import { colorEnum, sizeEnum } from "../types";
import sendApiRequest from "./send-api-request";

export const getPackages = async (params?: string | string[] | object) => {
  return await sendApiRequest("packages/", "get", params, {});
};

export const addPackage = async (
  token: string,
  name: string,
  itemIds: string[],
  totalPrice: number,
) => {
  return await sendApiRequest(
    "packages/",
    "post",
    {},
    { name, itemIds, totalPrice },
    { "x-auth-token": token },
  );
};

export const removePackage = async (token: string, packageId: string) => {
  return await sendApiRequest(
    `packages/${packageId}`,
    "delete",
    {},
    {},
    { "x-auth-token": token },
  );
};

export const deletePackageFromCart = async (
  token: string,
  packageId: string,
) => {
  return await sendApiRequest(
    `packages/cart/${packageId}`,
    "delete",
    {},
    {},
    { "x-auth-token": token },
  );
};

export const addPackageToCart = async (
  token: string,
  packageId: string,
  colors: colorEnum[],
  sizes: sizeEnum[],
) => {
  return await sendApiRequest(
    `packages/cart/${packageId}`,
    "post",
    {},
    { colors, sizes },
    { "x-auth-token": token },
  );
};
