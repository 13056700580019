import { Spinner as SpinnerBootstrap } from "react-bootstrap";

export default function Spinner({ fullWidth = true }: { fullWidth?: boolean }) {
  const { innerWidth: width, innerHeight: height } = window;
  return (
    <div
      className="d-flex align-items-center justify-content-center m-5"
      style={fullWidth ? { width, height } : {}}
    >
      <SpinnerBootstrap
        style={
          fullWidth
            ? { width: width * 0.2, height: width * 0.2, alignSelf: "center" }
            : {}
        }
      />
    </div>
  );
}
