import { useEffect, useRef, useState } from "react";
import { Navbar, Nav, Button, Row, Col, Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../redux/user";
import { getItems } from "../api/items";
import { ItemType } from "../types";
import { useNavigate } from "react-router-dom";
import Canvas from "./Canvas";
import SearchBar from "./Common/SearchBar";
import { primaryColor } from "../utils/shared";

const NavbarComponent = () => {
  const token = useSelector((state: any) => state.user.token);
  const isAdmin = useSelector((state: any) => state.user.isAdmin);

  const ref = useRef<HTMLDivElement>();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState<string>("");
  const [suggestions, setSuggestions] = useState<ItemType[]>([]);
  const [showDropDown, setShowDropDown] = useState<boolean>(true);
  const [showOffCanvas, setShowOffCanvas] = useState<boolean>(false);
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
  const [nabBarHeight, setNavBarHeight] = useState<number>(0);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (searchInput && searchInput !== "") {
        const items = await getItems({ name: searchInput, page_size: 5 });
        setSuggestions(items.data);
      } else setSuggestions([]);
    };
    fetchSuggestions();
    if (ref.current) setNavBarHeight(ref.current.clientHeight);
  }, [searchInput]);

  const handleOnSuggestionPress = (id: string) => {
    setSearchInput("");
    navigate(`/items/details/${id}`);
  };

  const handleLogout = () => {
    dispatch(setToken(undefined));
  };

  return (
    <>
      {showSearchBar && (
        <SearchBar
          height={nabBarHeight}
          searchInput={searchInput}
          showDropDown={showDropDown}
          suggestions={suggestions}
          setShowSearchBar={setShowSearchBar}
          setSearchInput={setSearchInput}
          handleOnSuggestionPress={handleOnSuggestionPress}
          setShowDropDown={setShowDropDown}
        />
      )}
      <div
        className={`p-2 ${!showSearchBar && "sticky-top"}`}
        style={{ backgroundColor: primaryColor }}
        ref={ref}
      >
        {!isAdmin ? (
          <>
            <Canvas
              showOffCanvas={showOffCanvas}
              setShowOffCanvas={setShowOffCanvas}
            />
            <Stack
              direction="horizontal"
              className="justify-content-between align-items-center"
            >
              <img
                loading="lazy"
                onClick={() => setShowOffCanvas(true)}
                width="24"
                height="24"
                src="https://img.icons8.com/ios/50/ffffff/menu--v7.png"
                alt="menu--v7"
              />
              <Navbar.Brand
                href="/"
                style={{
                  fontSize: 24,
                  color: "white",
                  marginLeft: "auto",
                  marginRight: "auto",
                  position: "absolute",
                  left: "50%",
                  transform: "translate(-50%,0)",
                }}
              >
                <div>Richness</div>
              </Navbar.Brand>
              <Nav className="mr-auto align-items-center">
                <Nav.Link onClick={() => setShowSearchBar(true)}>
                  <img
                    loading="lazy"
                    width="24"
                    height="24"
                    src="https://img.icons8.com/ios/50/ffffff/search--v1.png"
                    alt="search--v1"
                  />
                </Nav.Link>
                <Nav.Link href="/cart">
                  <img
                    loading="lazy"
                    width="24"
                    height="24"
                    src="https://img.icons8.com/pastel-glyph/64/ffffff/paper-bag--v2.png"
                    alt="paper-bag--v2"
                  />
                </Nav.Link>
              </Nav>
            </Stack>
          </>
        ) : (
          <Row>
            <Col>
              <Nav.Link href="/dashboard/ordersTrack">
                <Button>Track Orders</Button>
              </Nav.Link>
            </Col>
            <Col>
              <Nav.Link href="/dashboard/addItem">
                <Button>Add Item</Button>
              </Nav.Link>
            </Col>
            <Col>
              <Nav.Link href="/dashboard/manageItems">
                <Button>Manage Items</Button>
              </Nav.Link>
            </Col>
            <Col>
              <Nav.Link href="/dashboard/addCategory">
                <Button>Add Category</Button>
              </Nav.Link>
            </Col>
            <Col>
              <Nav.Link href="/dashboard/addCoupon">
                <Button>Add Coupon</Button>
              </Nav.Link>
            </Col>
            <Col>
              <Nav.Link href="/dashboard/homeImages">
                <Button>Home Images</Button>
              </Nav.Link>
            </Col>
            <Col>
              <Nav.Link href="/dashboard/editCategory">
                <Button>Edit category</Button>
              </Nav.Link>
            </Col>
            <Col>
              <Nav.Link href="/dashboard/packages">
                <Button>Packages</Button>
              </Nav.Link>
            </Col>
            <Col>
              {token ? (
                <Button variant="secondary" onClick={handleLogout}>
                  Logout
                </Button>
              ) : undefined}
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default NavbarComponent;
