const ColorCircle = ({
  color,
  width,
  withStroke,
}: {
  color: string;
  width: number;
  withStroke: boolean;
}) => {
  return (
    <div
      className="color-circle-container"
      style={{ width: width, height: width }}
    >
      <svg
        className="color-circle"
        viewBox="-10 -10 200 200"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="55" cy="55" r="50" fill={color} stroke="black" />
        <circle
          cx="55"
          cy="55"
          r="60"
          fill="none"
          stroke={withStroke ? "gray" : "lightGray"}
          strokeWidth="10"
        />
      </svg>
    </div>
  );
};

export default ColorCircle;
