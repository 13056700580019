import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { categoryType } from "./Item/CategoriesShowCase";
import { useEffect, useState } from "react";
import { getCategories } from "../api/categories";
import CategoryCard from "./Category/CategoryCard";

export default function Shop() {
  const [categories, setCategories] = useState<categoryType[]>();

  useEffect(() => {
    const fetchData = async () => {
      const c = (await getCategories()).data;
      setCategories(c);
    };
    fetchData();
  }, []);

  const navigate = useNavigate();
  return (
    <Container className="align-items-center mt-3">
      {categories && (
        <>
          <h1 className="pb-3 text-center">Categories</h1>
          <Row>
            {categories.map((category) => (
              <Col
                key={category.id}
                md={3}
                xs={12}
                sm={6}
                role="button"
                className="mb-3"
                onClick={() => navigate(`/category/${category.id}`)}
              >
                <CategoryCard name={category.name} image={category.image} />
              </Col>
            ))}
          </Row>
        </>
      )}
    </Container>
  );
}
