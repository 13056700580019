import { useSelector } from "react-redux";
import {
  getCartItems,
  getCartPrices,
  removeItemFromCart,
} from "../../api/cart";
import { useEffect, useState } from "react";
import { CartItemType, CartPricesType } from "../../types";
import { Col, Container, Row } from "react-bootstrap";
import Spinner from "../Spinner";
import { getUserData } from "../../api/user";
import { egyptGovernorates } from "../../constants";
import CartForm from "./CartForm";
import { AxiosError } from "axios";
import { makeOrder } from "../../api/order";
import { useNavigate } from "react-router-dom";
import CartPrices from "./CartPrices";
import CartItem from "./CartItem";

const Cart = () => {
  const navigate = useNavigate();
  const token = useSelector((state: any) => state.user.token);

  const [cartPrices, setCartPrices] = useState<CartPricesType>();
  const [cartPricesLoading, setCartPricesLoading] = useState<boolean>(false);
  const [cartPricesError, setCartPricesError] = useState<boolean>(false);
  const [cartItems, setCartItems] = useState<CartItemType[]>();
  const [cartItemsLoading, setCartItemsLoading] = useState<boolean>(false);
  const [cartItemsError, setCartItemsError] = useState<boolean>(false);
  const [couponCode, setCouponCode] = useState<string>("");
  const [couponError, setCouponError] = useState<boolean>(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [apartment, setApartment] = useState("");
  const [city, setCity] = useState("");
  const [governorate, setGovernorate] = useState(egyptGovernorates[0]);
  const [note, setNote] = useState("");
  const [instagramHandle, setInstagramHandle] = useState("");
  const [orderLoading, setOrderLoading] = useState<boolean>(false);
  const [orderError, setOrderError] = useState();

  const fetchCartItems = async () => {
    try {
      setCartItemsLoading(true);
      setCartItemsError(false);
      const items = await getCartItems(token);
      setCartItems(items.data);
      setCartItemsLoading(false);
    } catch (err) {
      setCartItemsError(true);
    }
  };

  const fetchCartPrices = async () => {
    try {
      setCartPricesLoading(true);
      setCartPricesError(false);
      setCouponError(false);
      const prices = await getCartPrices(token, couponCode, governorate);
      setCartPrices(prices.data);
      if (couponCode !== "" && prices.data.discount === 0) setCouponError(true);
      setCartPricesLoading(false);
    } catch (err) {
      setCartPricesError(true);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = (await getUserData(token)).data;

        setPhoneNumber(userData.phone_number);
        setAddress(userData.address);
        setFirstName(userData.first_name);
        setLastName(userData.last_name);
        setSecondaryPhoneNumber(userData.secondary_phone_number);
        setApartment(userData.apartment);
        setCity(userData.city);
        setGovernorate(userData.governorate ?? governorate);
      } catch (err) {
        console.log(err);
      }
    };
    fetchUserData();
    fetchCartItems();
    fetchCartPrices();
  }, []);

  useEffect(() => {
    fetchCartPrices();
  }, [governorate]);

  const handleRemoveItemFromCart = async (id: string) => {
    try {
      await removeItemFromCart(token, id);
      fetchCartItems();
      fetchCartPrices();
    } catch (err) {}
  };

  const handleOnApplyCoupon = () => {
    fetchCartPrices();
  };

  const handleOnItemClick = (id: string) => {
    navigate(`/items/details/${id}`);
  };

  const handleOnCreateOrder = async () => {
    if (!orderLoading) {
      try {
        setOrderError(undefined);
        setOrderLoading(true);
        await makeOrder(
          {
            firstName,
            lastName,
            phoneNumber,
            secondaryPhoneNumber,
            apartment,
            address,
            city,
            governorate,
            image: "i",
            couponCode: !couponError ? couponCode : "",
            instagramHandle,
          },
          token,
        );
        navigate("/myOrders");
      } catch (e) {
        if (e instanceof AxiosError) {
          setOrderError(
            typeof e.response?.data === "string"
              ? e.response?.data
              : e.response?.data.errors.message,
          );
        }
      } finally {
        setOrderLoading(false);
      }
    }
  };

  const isSubmitButtonDisabled =
    cartItemsError ||
    cartPricesError ||
    cartItemsLoading ||
    cartPricesLoading ||
    cartItems?.length === 0 ||
    orderLoading;

  return (
    <Container className="min-vh-100">
      <Row className="pt-3">
        <Col md={6}>
          {!orderLoading ? (
            <CartForm
              phoneNumber={phoneNumber}
              secondaryPhoneNumber={secondaryPhoneNumber}
              address={address}
              city={city}
              governorate={governorate}
              apartment={apartment}
              firstName={firstName}
              lastName={lastName}
              note={note}
              error={orderError}
              isSubmitButtonDisabled={isSubmitButtonDisabled}
              instagramHandle={instagramHandle}
              shippingFees={cartPrices?.shippingFees}
              setAddress={setAddress}
              setApartment={setApartment}
              setCity={setCity}
              setFirstName={setFirstName}
              setGovernorate={setGovernorate}
              setLastName={setLastName}
              setSecondaryPhoneNumber={setSecondaryPhoneNumber}
              setNote={setNote}
              setInstagramHandle={setInstagramHandle}
              onSubmit={handleOnCreateOrder}
            />
          ) : (
            <Spinner />
          )}
        </Col>
        <Col md={6}>
          {cartItemsError ? (
            <></>
          ) : cartItemsLoading ? (
            <Spinner fullWidth={false} />
          ) : (
            <>
              {!cartItems || cartItems.length === 0 ? (
                <h2>Your cart is empty Keep Shopping</h2>
              ) : (
                <>
                  {cartItems.map((item) => (
                    <CartItem
                      key={item.item_id}
                      item={item}
                      handleRemoveItemFromCart={() =>
                        handleRemoveItemFromCart(item.id)
                      }
                      handleOnItemClick={() => handleOnItemClick(item.item_id)}
                    />
                  ))}
                  <CartPrices
                    couponCode={couponCode}
                    couponError={couponError}
                    cartPrices={cartPrices}
                    setCouponCode={setCouponCode}
                    handleOnApplyCoupon={handleOnApplyCoupon}
                    cartPricesLoading={cartPricesLoading}
                    cartPricesError={cartPricesError}
                  />
                </>
              )}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Cart;
