import { useSelector } from "react-redux";
import ItemsShowcase from "./Item/ItemsShowCase";
import { useEffect, useState } from "react";
import HomeModal from "./HomeModal";
import {
  imagePathMaker,
  primaryColor,
  sectionTwoImagesKey,
} from "../utils/shared";
import Shop from "./Shop";
import BestSellers from "./Item/BestSellers";
import { getSetting } from "../api/settings";
import { Col, Row } from "react-bootstrap";

interface HomePageProps {
  showHomeModal: boolean;
  setShowHomeModal: (b: boolean) => void;
}

const HomePage = ({ showHomeModal, setShowHomeModal }: HomePageProps) => {
  const { innerWidth: width, innerHeight: height } = window;

  const token = useSelector((state: any) => state.user.token);

  const [isHomeModalVisible, setIsHomeModalVisible] = useState<boolean>(false);
  const [sectionTwoImages, setSectionTwoImages] = useState<string[]>();

  useEffect(() => {
    const fetch = async () => {
      try {
        setTimeout(() => {
          setIsHomeModalVisible(!token && showHomeModal);
          setShowHomeModal(false);
        }, 2000);
        const sectionOne = (await getSetting(sectionTwoImagesKey)).data as {
          value: string;
        };
        setSectionTwoImages(
          sectionOne?.value === ""
            ? []
            : sectionOne?.value
                ?.split(/[^0-9]+/)
                .map((image) => imagePathMaker(image)),
        );
      } catch (err) {
        console.log(err);
      }
    };
    fetch();
  }, [token]);

  return (
    <div>
      <ItemsShowcase />
      <HomeModal
        showModal={isHomeModalVisible}
        setShowModal={setIsHomeModalVisible}
      />
      <div
        style={{
          backgroundColor: "#E2E2E2",
          paddingTop: 30,
          paddingBottom: 30,
        }}
      >
        <div
          className="rotating-text-container pt-1"
          style={{
            backgroundColor: primaryColor,
            width: "100%",
            height: 30,
            textAlign: "center",
          }}
        >
          <h5 className="rotating-text w-100">
            Life isn't perfect but Richness can be. &ensp; Life isn't perfect
            but Richness can be. &ensp; Life isn't perfect but Richness can be.
          </h5>
        </div>
      </div>
      <BestSellers />
      <Shop />
      <Row>
        {sectionTwoImages?.map((image) => (
          <Col xs={6} md={3}>
            <img
              loading="lazy"
              src={image}
              style={{
                width: "100%",
                aspectRatio: 1,
                height: height / 4,
                objectFit: "cover",
                objectPosition: "50% 0%",
              }}
              className="rounded-5 item mb-5"
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default HomePage;
