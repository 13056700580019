import { Container } from "react-bootstrap";

const ErrorPage = () => {
  const { innerWidth: width, innerHeight: height } = window;

  return (
    <Container
      fluid
      className="d-flex text-center bg-light p-5 align-items-center"
      style={{ width, height }}
    >
      <Container>
        <h1>This route is not found</h1>
      </Container>
    </Container>
  );
};

export default ErrorPage;
