import { Alert, Button, Form, FormControl, Stack } from "react-bootstrap";
import { egyptGovernorates } from "../../constants";
import { primaryColor } from "../../utils/shared";

interface CartFormProps {
  phoneNumber: string;
  secondaryPhoneNumber: string;
  firstName: string;
  lastName: string;
  governorate: string;
  address: string;
  apartment: string;
  city: string;
  note: string;
  error: string;
  isSubmitButtonDisabled: boolean;
  instagramHandle: string;
  shippingFees?: number;
  setSecondaryPhoneNumber: (data: string) => void;
  setFirstName: (data: string) => void;
  setLastName: (data: string) => void;
  setGovernorate: (data: string) => void;
  setAddress: (data: string) => void;
  setApartment: (data: string) => void;
  setCity: (data: string) => void;
  setNote: (data: string) => void;
  setInstagramHandle: (data: string) => void;
  onSubmit: () => void;
}
const CartForm = ({
  phoneNumber,
  secondaryPhoneNumber,
  firstName,
  lastName,
  governorate,
  address,
  apartment,
  city,
  note,
  error,
  isSubmitButtonDisabled,
  instagramHandle,
  shippingFees,
  setSecondaryPhoneNumber,
  setFirstName,
  setLastName,
  setGovernorate,
  setAddress,
  setApartment,
  setCity,
  setNote,
  setInstagramHandle,
  onSubmit,
}: CartFormProps) => {
  return (
    <>
      <h1 className="mb-3">Cart</h1>
      <hr />
      <Form className="mt-5 gap-3">
        <Stack gap={3}>
          <FormControl
            type="number"
            placeholder="Phone Number"
            value={phoneNumber}
            minLength={11}
            maxLength={11}
            required
          />
          <FormControl
            type="number"
            placeholder="Secondary Phone Number"
            value={secondaryPhoneNumber}
            onChange={(e) => setSecondaryPhoneNumber(e.target.value)}
            minLength={11}
            maxLength={11}
            required
          />
          <Stack direction="horizontal" gap={3}>
            <FormControl
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
            <FormControl
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </Stack>

          <FormControl
            type="text"
            placeholder="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />

          <Form.Group controlId="governorateSelect">
            <Form.Label>Governorate</Form.Label>
            <Form.Control
              as="select"
              value={governorate}
              onChange={(e) => setGovernorate(e.target.value)}
            >
              {egyptGovernorates.map((governorate, index) => (
                <option key={index} value={governorate}>
                  {governorate}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Stack direction="horizontal" gap={2}>
            <FormControl
              type="text"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
            <FormControl
              type="text"
              placeholder="Apartment"
              value={apartment}
              onChange={(e) => setApartment(e.target.value)}
              required
            />
          </Stack>

          <FormControl
            type="text"
            placeholder="Instagram handle"
            value={instagramHandle}
            onChange={(e) => setInstagramHandle(e.target.value)}
          />

          <FormControl
            type="text"
            as="textarea"
            rows={3}
            placeholder="Note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />

          <h6>Shipping Method</h6>
          <Alert variant="danger" className="d-flex justify-content-between">
            <div>Standard</div>
            <div>E£ {shippingFees}.00</div>
          </Alert>

          <Button
            className="mt-5"
            onClick={onSubmit}
            disabled={isSubmitButtonDisabled}
            variant="danger"
            style={{ backgroundColor: primaryColor }}
          >
            Complete Order
          </Button>
          {error ? <Alert variant="danger">{error}</Alert> : undefined}
        </Stack>
      </Form>
    </>
  );
};
export default CartForm;
