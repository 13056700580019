// src/components/Login.js
import { useState } from "react";
import { useDispatch } from "react-redux";

import { setToken } from "../../redux/user";
import axios, { AxiosError } from "axios";
import { Button, Container, FormControl, Form } from "react-bootstrap";
import { API_BASE_URL, primaryColor } from "../../utils/shared";
import { useNavigate } from "react-router-dom";

const AuthorizationModal = () => {
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLogin, setIsLogin] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    console.log("submit");
    try {
      e.preventDefault();
      const response = await axios.post(
        `${API_BASE_URL}users/${isLogin ? "login" : "signup"}`,
        {
          username,
          password,
          phoneNumber: isLogin ? undefined : phoneNumber,
        },
      );
      const token = response.headers["x-auth-token"];
      dispatch(setToken(token));
      resetFields();
      navigate(-1);
    } catch (e) {
      if (e instanceof AxiosError) {
        setError(
          typeof e.response?.data === "string"
            ? e.response?.data
            : e.response?.data.errors.message,
        );
      }
    }
  };

  const changeType = () => {
    resetFields();
    setIsLogin((login) => !login);
  };

  const resetFields = () => {
    setUsername("");
    setPassword("");
    setPhoneNumber("");
    setError("");
  };

  return (
    <Container
      className="p-5 text-center"
      style={{ backgroundColor: "#ffffff" }}
    >
      <h2>{isLogin ? "Login" : "Create Account"}</h2>
      <Form
        onSubmit={handleSubmit}
        className="p-5 d-flex flex-column gap-3 align-items-center gap-3"
      >
        <FormControl
          type="username"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={{ width: 300 }}
        />
        {!isLogin ? (
          <FormControl
            type="number"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            style={{ width: 300 }}
          />
        ) : undefined}

        <FormControl
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ width: 300 }}
        />
        <Button
          variant="danger"
          style={{ backgroundColor: primaryColor, width: 150 }}
          className="rounded-5"
          type="submit"
        >
          {!isLogin ? "Create Account" : "Login"}
        </Button>
        {error ? <div color="red">{error}</div> : undefined}
        {isLogin ? (
          <div
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={changeType}
          >
            Create account
          </div>
        ) : undefined}
      </Form>
    </Container>
  );
};

export default AuthorizationModal;
