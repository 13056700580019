import { useEffect, useState } from "react";
import { Alert, Container, NavLink } from "react-bootstrap";
import { primaryColor } from "../utils/shared";

const Footer = () => {
  const [show, setShow] = useState<boolean>(false);
  const copyLinkToClipboard = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShow(true);
    navigator.clipboard.writeText(`+20 108 089 4704`);
  };
  useEffect(() => {
    if (show) {
      setTimeout(() => setShow(false), 3000);
    }
  }, [show]);

  return (
    <footer
      className="text-light py-3 position-relative footer"
      style={{ backgroundColor: primaryColor, height: "50vh" }}
    >
      <Container className="align-items-center d-flex flex-column gap-3">
        <h1>Where to find us</h1>
        <p>Got any questions ?</p>
        <NavLink
          href="https://www.facebook.com/haven.vision1"
          target="_blank"
          className="d-flex align-items-center justify-content-between"
          style={{ width: 200 }}
        >
          <img
            loading="lazy"
            width="32"
            height="32"
            src="https://img.icons8.com/ios-filled/32/ffffff/facebook--v1.png"
            alt="facebook--v1"
          />
          <div>@Richness.eg</div>
        </NavLink>
        <NavLink
          href="https://www.tiktok.com/@richness.eg"
          target="_blank"
          className="d-flex align-items-center justify-content-between"
          style={{ width: 200 }}
        >
          <img
            loading="lazy"
            width="32"
            height="32"
            src="https://img.icons8.com/ios-filled/32/ffffff/tiktok--v1.png"
            alt="tiktok--v1"
          />
          <div>@Richness.eg</div>
        </NavLink>
        <NavLink
          href="https://www.instagram.com/richness.eg"
          target="_blank"
          className="d-flex align-items-center justify-content-between"
          style={{ width: 200 }}
        >
          <img
            loading="lazy"
            width="32"
            height="32"
            src="https://img.icons8.com/ios-filled/32/ffffff/instagram-new--v1.png"
            alt="instagram-new--v1"
          />
          <div>@Richness.eg</div>
        </NavLink>
        <NavLink
          className="d-flex align-items-center justify-content-between"
          onClick={copyLinkToClipboard}
          style={{ width: 200 }}
        >
          <img
            loading="lazy"
            width="32"
            height="32"
            src="https://img.icons8.com/ios-filled/50/ffffff/whatsapp--v1.png"
            alt="whatsapp--v1"
          />
          <p>+20 108 089 4704</p>
        </NavLink>
        <Alert variant="info" style={{ display: show ? "inherit" : "none" }}>
          number copied to clipboard
        </Alert>
      </Container>
      <div style={{ fontSize: 12, bottom: 5, left: 5, position: "absolute" }}>
        @ 2024, RICHNESS EG
      </div>
    </footer>
  );
};

export default Footer;
