import { useEffect, useState } from "react";
import { Button, Carousel, Col, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  homeImagesKey,
  imagePathMaker,
  primaryColor,
  sectionOneImagesKey,
} from "../../utils/shared";
import { getSetting } from "../../api/settings";

const ItemsShowcase = () => {
  const { innerWidth: width, innerHeight: height } = window;
  const [images, setImages] = useState<string[]>();
  const [sectionOneImages, setSectionOneImages] = useState<string[]>();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const items = (await getSetting(homeImagesKey)).data as { value: string };
      setImages(
        items?.value?.split(/[^0-9]+/).map((image) => imagePathMaker(image)),
      );

      const sectionOne = (await getSetting(sectionOneImagesKey)).data as {
        value: string;
      };
      setSectionOneImages(
        sectionOne?.value === ""
          ? []
          : sectionOne?.value
              ?.split(/[^0-9]+/)
              .map((image) => imagePathMaker(image)),
      );
    };
    fetchData();
  }, []);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleOnShopNowClick = () => {
    navigate("/shop");
  };

  return (
    <>
      {images ? (
        <div className="d-flex flex-column">
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            className="align-center"
            indicators={false}
          >
            {images.map((image) => {
              return (
                <Carousel.Item key={image}>
                  <div className="d-flex justify-content-center">
                    <img
                      loading="lazy"
                      src={image}
                      style={{
                        width,
                        height: height / 1.2,
                        aspectRatio: 1,
                        objectFit: "cover",
                        objectPosition: "50% 0%",
                      }}
                    />
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>

          <div
            style={{
              color: "black",
              alignSelf: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="d-flex row p-3 my-5"
          >
            <h2 className="text-center mb-4">Shop All Collections</h2>
            <Button
              className="w-50 mb-3 rounded-pill"
              variant="danger"
              style={{ backgroundColor: primaryColor }}
              onClick={() => handleOnShopNowClick()}
            >
              Shop Now
            </Button>
          </div>

          <Row>
            {sectionOneImages?.map((image) => (
              <Col xs={6} md={3}>
                <img
                  loading="lazy"
                  src={image}
                  style={{
                    width: "100%",
                    aspectRatio: 1,
                    height: height / 4,
                    objectFit: "cover",
                    objectPosition: "50% 0%",
                  }}
                  className="rounded-5 item mb-5"
                />
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ width, height }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};

export default ItemsShowcase;
