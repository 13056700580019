import { Alert, Button, Stack } from "react-bootstrap";
import { CartPricesType } from "./../../types";
import Spinner from "../Spinner";
import { primaryColor } from "../../utils/shared";

interface CartPricesProps {
  couponCode: string;
  couponError: boolean;
  cartPrices: CartPricesType;
  cartPricesLoading: boolean;
  cartPricesError: boolean;
  setCouponCode: (code: string) => void;
  handleOnApplyCoupon: () => void;
}
const CartPrices = ({
  couponCode,
  couponError,
  cartPrices,
  cartPricesError,
  cartPricesLoading,
  setCouponCode,
  handleOnApplyCoupon,
}: CartPricesProps) => {
  const [couponDiscountFirst, couponDiscountSecond] = (
    ((cartPrices?.discount - cartPrices?.firstOrderDiscount) / 100.0) *
    cartPrices?.totalPriceWithoutDiscount
  )
    .toString()
    .split(".");

  const [totalDiscountFirst, totalDiscountSecond] = (
    (cartPrices?.discount / 100.0) *
    cartPrices?.totalPriceWithoutDiscount
  )
    .toString()
    .split(".");

  const [firstOrderDiscountFirst, firstOrderDiscountSecond] = (
    (cartPrices?.firstOrderDiscount / 100.0) *
    cartPrices?.totalPriceWithoutDiscount
  )
    .toString()
    .split(".");

  return (
    <>
      <Stack
        direction="horizontal"
        className="mx-3 my-2 d-flex align-items-center"
        gap={3}
      >
        <div style={{ width: "80%" }}>
          <input
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            style={{
              width: "100%",
              borderColor: couponError
                ? "red"
                : cartPrices?.discount !== 0
                  ? "green"
                  : "black",
            }}
          />
          {couponError ? (
            <Alert variant="danger" className="py-2 my-2">
              Enter a valid code
            </Alert>
          ) : undefined}
        </div>
        <Button
          className="rounded-pill"
          onClick={handleOnApplyCoupon}
          variant="danger"
          style={{ backgroundColor: primaryColor, width: "20%" }}
        >
          Apply
        </Button>
      </Stack>
      {cartPricesError ? (
        <Alert variant="warning">Server Error in getting the prices</Alert>
      ) : cartPricesLoading ? (
        <Spinner fullWidth={false} />
      ) : (
        <>
          <Stack
            direction="horizontal"
            className="mx-3 my-2 justify-content-between"
          >
            <div>SubTotal</div>
            <div>E£ {(cartPrices?.totalPriceWithoutDiscount).toFixed(2)}</div>
          </Stack>
          {cartPrices?.discount !== 0 ? (
            <>
              {cartPrices.firstOrderDiscount !== 0 ? (
                <Stack
                  direction="horizontal"
                  className="mx-3 my-2 justify-content-between"
                  style={{ color: "darkGreen" }}
                >
                  <div>First Order Discount</div>
                  <div>
                    -E£{" "}
                    {[
                      firstOrderDiscountFirst,
                      firstOrderDiscountSecond?.slice(0, 2),
                    ].join(".")}
                  </div>
                </Stack>
              ) : undefined}

              {cartPrices.discount !== cartPrices.firstOrderDiscount ? (
                <Stack
                  direction="horizontal"
                  className="mx-3 my-2 justify-content-between"
                  style={{ color: "darkGreen" }}
                >
                  <div>Coupon discount</div>
                  <div>
                    -E£{" "}
                    {[
                      couponDiscountFirst,
                      couponDiscountSecond?.slice(0, 2),
                    ].join(".")}
                  </div>
                </Stack>
              ) : undefined}

              <Stack
                direction="horizontal"
                className="mx-3 my-2 justify-content-between"
                style={{ color: "darkGreen" }}
              >
                <div>Total Discount</div>
                <div>
                  -E£{" "}
                  {[totalDiscountFirst, totalDiscountSecond?.slice(0, 2)].join(
                    ".",
                  )}
                </div>
              </Stack>
            </>
          ) : undefined}
          <Stack
            direction="horizontal"
            className="mx-3 my-2 justify-content-between"
          >
            <div>Shipping</div>
            <div>E£ {cartPrices?.shippingFees}</div>
          </Stack>
          <Stack
            direction="horizontal"
            className="mx-3 my-2 justify-content-between"
          >
            <div>
              <h4>Total</h4>
            </div>
            <div>E£ {(cartPrices?.finalPrice).toFixed(2)}</div>
          </Stack>
        </>
      )}
    </>
  );
};

export default CartPrices;
