import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ItemType } from "../../types";
import { getItems } from "../../api/items";
import { categoryType } from "./CategoriesShowCase";
import { getCategories } from "../../api/categories";
import ItemCard from "./ItemCard";
import { useNavigate } from "react-router-dom";

export default function DashboardItemEdit() {
  const [items, setItems] = useState<ItemType[]>([]);
  const [categories, setCategories] = useState<categoryType[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const i = (await getItems({ page_size: 100 })).data;
      setItems(i);

      const c = (await getCategories()).data;
      setCategories(c);
    };
    fetchData();
  }, []);
  return (
    <Container className="p-3 min-vh-100">
      {categories.map((c) => {
        const i = items.filter((item) => item.category_id === c.id);
        return (
          <Row style={{ display: i.length === 0 && "none" }} key={c.id}>
            <h2>{c.name}</h2>
            {i.map((item) => (
              <Col md={3} xs={6} sm={6} key={item.id}>
                <ItemCard
                  item={item}
                  onClick={() =>
                    navigate("/dashboard/addItem", { state: { item } })
                  }
                />
              </Col>
            ))}
          </Row>
        );
      })}
    </Container>
  );
}
