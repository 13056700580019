import { Col, Container, Row } from "react-bootstrap";
import CategoryCard from "./CategoryCard";
import { categoryType } from "../Item/CategoriesShowCase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCategories } from "../../api/categories";

export default function DashboardEditCategory() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState<categoryType[]>();

  useEffect(() => {
    const fetchData = async () => {
      const c = (await getCategories()).data;
      setCategories(c);
    };
    fetchData();
  }, []);
  return (
    <Container className="p-3 min-vh-100">
      <h2>Categories</h2>
      {categories && (
        <>
          <Row>
            {categories.map((category) => (
              <Col
                key={category.id}
                md={3}
                xs={12}
                sm={6}
                role="button"
                className="mb-3"
                onClick={() =>
                  navigate(`/dashboard/addCategory`, {
                    state: {
                      name: category.name,
                      image: category.image,
                      id: category.id,
                    },
                  })
                }
              >
                <CategoryCard name={category.name} image={category.image} />
              </Col>
            ))}
          </Row>
        </>
      )}
    </Container>
  );
}
