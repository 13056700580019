import axios, { Method } from "axios";
import { API_BASE_URL } from "../utils/shared";

export default async function sendApiRequest(
  path: string,
  method: Method,
  params?: string | object | string[],
  body?: string | object | undefined,
  headers: { [key: string]: string } = {},
  url?: string,
) {
  const baseUrl = url ? url : API_BASE_URL;

  const fetchUrl = baseUrl + path;
  return axios.request({
    method,
    headers,
    params,
    url: fetchUrl,
    data: body,
  });
}
