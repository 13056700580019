import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Button, Col, Alert } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { getItems } from "../../api/items";
import { getCategories } from "../../api/categories";
import Spinner from "../Spinner";
import PriceFilter from "../PriceFilter";
import { CategoryType, ItemType } from "../../types";
import { useSelector } from "react-redux";
import ItemCard from "../Item/ItemCard";
import { capitalizeFirstLetter, primaryColor } from "../../utils/shared";

const CategoryDetails = () => {
  const { id } = useParams();

  const token = useSelector((state: any) => state.user.token);

  const [categoryItems, setCategoryItems] = useState<ItemType[]>();
  const [viewableCategoryItems, setViewableCategoryItems] =
    useState<ItemType[]>();
  const [categories, setCategories] = useState<CategoryType[]>();
  const [outOfStock, setOutOfStock] = useState(true);
  const [maxPrice, setMaxPrice] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const categoryName = categories?.filter((c) => c.id === id)[0].name;

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setIsError(false);
      const items = (
        await getItems(
          {
            category_id: id,
            availability: !outOfStock ? true : undefined,
          },
          token,
        )
      ).data;
      const c = await getCategories();
      setCategories(c.data);
      setCategoryItems(items);
      setViewableCategoryItems(items);
      setMaxPrice(Math.max(...items.map((i) => i.price)));
    } catch (err) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [outOfStock, id]);

  const navigate = useNavigate();

  const handleOnCategoryClick = (id: string) => {
    navigate(`/category/${id}`);
  };

  const handleShowOutOfStock = () => {
    setOutOfStock(true);
  };

  const handleHideOutOfStock = () => {
    setOutOfStock(false);
  };

  const handleOnPriceFilterChange = (min: number, max: number) => {
    setViewableCategoryItems(() => {
      return categoryItems.filter(
        (item) => item.price >= min && item.price <= max,
      );
    });
  };

  const onLikePress = () => {
    fetchData();
  };

  return (
    <Container className="p-3 min-vh-100">
      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <Alert variant="warning"> Server error please try again later</Alert>
      ) : categories && categoryItems ? (
        <Row>
          <Col md={3}>
            <h5 className="mb-3">
              <img
                loading="lazy"
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/horizontal-settings-mixer--v1.png"
                alt="horizontal-settings-mixer--v1"
              />
              Filter
            </h5>
            <div className="mb-3">
              Out of stock &emsp;
              <Button
                variant={"light"}
                style={{
                  backgroundColor: outOfStock ? primaryColor : undefined,
                  color: outOfStock ? "white" : "black",
                }}
                onClick={handleShowOutOfStock}
                className="me-2 rounded-pill"
              >
                Show
              </Button>
              <Button
                variant="light"
                style={{
                  backgroundColor: !outOfStock ? primaryColor : undefined,
                  color: !outOfStock ? "white" : "black",
                }}
                onClick={handleHideOutOfStock}
                className="rounded-pill"
              >
                Hide
              </Button>
            </div>
            <PriceFilter
              maxPrice={maxPrice}
              onChange={handleOnPriceFilterChange}
            />
          </Col>
          <Col md={9}>
            <h2 className="text-center mt-3">
              {capitalizeFirstLetter(categoryName)}
            </h2>
            <Row className="py-3 justify-content-center">
              {categories.map((c) => (
                <Col key={c.id} xs={6} md={3} className="mb-2">
                  <Button
                    variant={c.name === categoryName ? "danger" : "secondary"}
                    className="rounded-pill"
                    style={{
                      backgroundColor:
                        c.name === categoryName ? primaryColor : undefined,
                    }}
                    key={c.id}
                    onClick={() => handleOnCategoryClick(c.id)}
                  >
                    {capitalizeFirstLetter(c.name)}
                  </Button>
                </Col>
              ))}
            </Row>
            <Row>
              {viewableCategoryItems.map((item) => (
                <Col md={3} xs={6} sm={6} className="mt-2" key={item.id}>
                  <ItemCard item={item} onLikeClick={onLikePress} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      ) : undefined}
    </Container>
  );
};

export default CategoryDetails;
