import sendApiRequest from "./send-api-request";

export const getUserData = async (token: string) => {
  return await sendApiRequest(
    "users/",
    "get",
    {},
    {},
    { "x-auth-token": token },
  );
};

export const getIfUserHasOrders = async (token: string) => {
  return await sendApiRequest(
    "users/orders",
    "get",
    {},
    {},
    { "x-auth-token": token },
  );
};
