import { Carousel, Container, NavLink } from "react-bootstrap";
import { FiveStarRatingType, ItemType } from "../types";
import { useEffect, useState } from "react";
import { getFiveStarRatings } from "../api/rating";
import ReactStars from "react-rating-stars-component";
import { getItems } from "../api/items";
import { useNavigate } from "react-router-dom";
import { primaryColor } from "../utils/shared";
import image from "../assets/contact us.webp";

export default function ContactUs() {
  const { innerWidth: width } = window;

  const navigate = useNavigate();

  const [fiveStarRatings, setFiveStarRatings] =
    useState<FiveStarRatingType[]>();
  const [items, setItems] = useState<ItemType[]>([]);
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    const fetchRatings = async () => {
      const ratings = (await getFiveStarRatings()).data;
      setFiveStarRatings(ratings);
      for (const rating of ratings) {
        const item = (await getItems({ id: rating.item_id })).data;
        setItems((i) => [...i, item[0]]);
      }
    };
    fetchRatings();
  }, []);

  console.log(height);

  return (
    // <Container className="align-items-center min-vh-100 mt-3">
    <>
      <img
        loading="lazy"
        src={image}
        width={width}
        onLoad={(e: any) => {
          setHeight(e.target.offsetHeight);
        }}
        style={{ objectFit: "fill", aspectRatio: 0.8 }}
      />
      <div
        style={{
          position: "absolute",
          zIndex: 1,
          top: height > 2000 ? height * 0.55 : height * 0.63,
          left: "15%",
        }}
      >
        <NavLink
          href="https://www.instagram.com/richness.eg"
          target="_blank"
          style={{ fontSize: height / 40 }}
        >
          <b>Instagram</b>
        </NavLink>
        <NavLink
          href="https://www.facebook.com/haven.vision1"
          target="_blank"
          style={{ fontSize: height / 40 }}
        >
          <b>Facebook</b>
        </NavLink>
        <NavLink
          href="https://www.tiktok.com/@richness.eg"
          target="_blank"
          className="text-bold"
          style={{ fontSize: height / 40 }}
        >
          <b>Tiktok</b>
        </NavLink>
      </div>
      {items &&
        items.length !== 0 &&
        fiveStarRatings &&
        fiveStarRatings.length !== 0 && (
          <>
            <h3
              style={{ alignItems: "center", justifyContent: "center" }}
              className="d-flex flex-row gap-3 mt-4"
            >
              5.0{" "}
              <ReactStars
                edit={false}
                count={5}
                size={24}
                value={5}
                activeColor={primaryColor}
              />
            </h3>
            <h3
              style={{ alignItems: "center", justifyContent: "center" }}
              className="d-flex flex-row gap-3 mt-4"
            >
              5 out of 5 stars based on {fiveStarRatings.length} reviews
            </h3>
            <Carousel
              className="text-center mt-5"
              activeIndex={activeImageIndex}
              onSelect={(i) => setActiveImageIndex(i)}
            >
              {fiveStarRatings.map((rating, index) => {
                return (
                  <Carousel.Item
                    tabIndex={index}
                    key={`${rating.item_id}/${rating.username}`}
                    className="gap-3"
                    style={{ backgroundColor: "white" }}
                  >
                    <h5>{rating.message}</h5>
                    <h6 style={{ color: "gray" }}>{rating.username}</h6>
                    <img
                      loading="lazy"
                      src={
                        items.find((i) => i.id === rating.item_id)?.images[0][0]
                      }
                      width={50}
                      height={50}
                      onClick={() =>
                        navigate(`/items/details/${rating.item_id}`)
                      }
                      style={{ alignSelf: "center" }}
                    />
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </>
        )}
    </>
    // </Container>
  );
}
