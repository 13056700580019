import { Button, CloseButton, Container, Modal } from "react-bootstrap";
import { primaryColor } from "../utils/shared";
import { useNavigate } from "react-router-dom";

interface HomeModalProps {
  showModal: boolean;
  setShowModal: (b: boolean) => void;
}

export default function HomeModal({ showModal, setShowModal }: HomeModalProps) {
  const navigate = useNavigate();
  const handleOnCancel = () => {
    setShowModal(false);
  };

  const handleOnAccept = () => {
    handleOnCancel();
    navigate("/login");
  };

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} centered>
      <Modal.Header closeButton={true} className="border-0" bsPrefix="" />

      <Container className="p-4 border rounded-3 d-flex flex-column align-items-center">
        <h3 className="text-center">
          Join Us Now for free to be a part of our family
        </h3>
        <div className="d-flex align-self-center flex-column my-4 w-100 gap-3">
          <Button
            className="rounded-3"
            onClick={handleOnAccept}
            variant="danger"
            style={{ backgroundColor: primaryColor }}
          >
            Join
          </Button>
          <Button
            onClick={handleOnCancel}
            variant="light"
            className="rounded-3"
          >
            No, Thanks
          </Button>
        </div>
        <p className="text-center">
          by joining us for the first time you will get a 10% off your first
          order
        </p>
      </Container>
    </Modal>
  );
}
