import { Col, Row, Image, Button } from "react-bootstrap";
import { CartItemType, mapColors } from "../../types";
import ColorCircle from "../Common/ColorCircle";

interface CartItemProps {
  item: CartItemType;
  handleRemoveItemFromCart?: () => void;
  handleOnItemClick: () => void;
}

const CartItem = ({
  item,
  handleRemoveItemFromCart,
  handleOnItemClick,
}: CartItemProps) => {
  return (
    <Row className="align-items-center mt-3" key={item.id}>
      <Col className="text-center">
        <Image
          role="button"
          src={item.images[0]}
          rounded
          height={100}
          onClick={handleOnItemClick}
        />
        <div>{item.quantity}</div>
      </Col>
      <Col>
        <div>
          <strong>{item.name}</strong>
        </div>
        <span> {item.color} </span>
        <ColorCircle
          color={mapColors[item.color]}
          withStroke={false}
          width={32}
        />
        <div>{item.size}</div>
      </Col>
      <Col className="text-right">
        <div>
          <strong>
            E£{" "}
            {(
              item.price *
              (1 - (parseFloat(item.discount) ?? 0) / 100)
            ).toFixed(2)}
          </strong>
        </div>
        {handleRemoveItemFromCart ? (
          <Button
            variant="danger"
            onClick={handleRemoveItemFromCart}
            className="rounded-pill"
          >
            x
          </Button>
        ) : undefined}
      </Col>
    </Row>
  );
};

export default CartItem;
