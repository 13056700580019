import image from "../assets/policy.webp";
export default function Policy() {
  const { innerWidth: width } = window;
  return (
    <img
      loading="lazy"
      src={image}
      width={width}
      style={{ objectFit: "fill", aspectRatio: 0.8 }}
    />
  );
}
