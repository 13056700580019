import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Row,
  Spinner,
  Stack,
} from "react-bootstrap";
import { addCategory, editCategory, getCategories } from "../../api/categories";
import { useSelector } from "react-redux";
import { CategoryType } from "../../types";
import { imagePathMaker } from "../../utils/shared";
import { useLocation } from "react-router-dom";

export default function DashboardCategory() {
  const token = useSelector((state: any) => state.user.token);

  const { state } = useLocation();

  const { id, image: defaultImage, name: defaultName } = state ?? {};

  const [categories, setCategories] = useState<CategoryType[]>();
  const [imageNumber, setImageNumber] = useState<string>(
    defaultImage?.split(/[^0-9]+/).join(""),
  );
  const [newCategoryName, setNewCategoryName] = useState<string>(defaultName);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const image = imageNumber ? imagePathMaker(imageNumber) : undefined;

  const fetchCategories = async () => {
    try {
      setLoading(true);
      setError(false);
      const c = await getCategories();
      setCategories(c.data);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleOnAddCategory = async () => {
    try {
      setLoading(true);
      setError(false);
      if (id) {
        await editCategory(token, id, newCategoryName, image);
      } else {
        await addCategory(token, newCategoryName, image);
      }
      await fetchCategories();
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="mt-5">
      <h2 className="pt-5">Categories</h2>
      {loading ? (
        <Spinner />
      ) : error ? (
        <Alert variant="warning"> Server error please try again later</Alert>
      ) : (
        <>
          <Row className="m-5">
            {categories &&
              categories.map((category) => (
                <Col>
                  <h5>{category.name}</h5>
                  <img loading="lazy" src={category.image} width={100} />
                </Col>
              ))}
          </Row>

          <hr />

          <Stack direction="vertical" gap={100}>
            <h3>Add a new category</h3>
            <Form onSubmit={handleOnAddCategory}>
              <FormControl
                type="text"
                placeholder="category name"
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.target.value)}
                required
              />
              <Row className="my-5">
                <Col md={2}>Image number</Col>
                <Col>
                  <input
                    type="numeric"
                    onChange={(e) => setImageNumber(e.target.value)}
                    value={imageNumber}
                    required
                  />
                </Col>
                {image ? (
                  <Col>
                    <img loading="lazy" src={image} width={100} height={100} />
                  </Col>
                ) : undefined}
              </Row>
              <Button variant="success" type="submit" className="mb-3">
                Add Category
              </Button>
            </Form>
          </Stack>
        </>
      )}
    </Container>
  );
}
