import { useSelector } from "react-redux";
import Cart from "../Cart/Cart";
import { likeItem } from "../../api/like";

export default function LikeIcon({
  liked,
  itemId,
  onPress,
}: {
  liked: boolean;
  itemId: string;
  onPress: () => void;
}) {
  const token = useSelector((state: any) => state.user.token);

  const toggleLike = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (token) {
      try {
        await likeItem({ itemId }, token);
        onPress();
      } catch (err) {}
    }
  };
  return (
    <img
      loading="lazy"
      onClick={toggleLike}
      width="32"
      height="32"
      src={
        liked
          ? "https://img.icons8.com/fluency/48/filled-like--v1.png"
          : "https://img.icons8.com/material-outlined/24/filled-like.png"
      }
      alt="filled-like--v1"
      style={{
        position: "absolute",
        right: "2vh",
        zIndex: 1,
        cursor: token ? "grab" : "default",
      }}
    />
  );
}
