import { Alert, Overlay } from "react-bootstrap";
import { base_url } from "../../utils/shared";
import { useEffect, useRef, useState } from "react";

export default function ShareIcon({ itemId }: { itemId: string }) {
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (show) {
      setTimeout(() => setShow(false), 3000);
    }
  }, [show]);

  const copyLinkToClipboard = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShow(true);
    navigator.clipboard.writeText(`${base_url}/items/details/${itemId}`);
  };
  return (
    <>
      <img
        loading="lazy"
        ref={ref}
        onClick={copyLinkToClipboard}
        width="24"
        height="24"
        src="https://img.icons8.com/fluency-systems-filled/48/share-3.png"
        alt="share-3"
        style={{
          cursor: "pointer",
          right: "3vh",
          zIndex: 1,
        }}
        className="mb-3"
      />
      <Overlay target={ref.current} show={show} placement="right">
        <Alert variant="info">link copied to clipboard</Alert>
      </Overlay>
    </>
  );
}
