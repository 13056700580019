import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  cancelOrder,
  changeOrderStatus,
  getDashboardOrders,
  getMyOrders,
  getOrderItems,
  getOrderItemsDashboard,
} from "../../api/order";
import { CartItemType, OrderStatusEnum, OrderType } from "../../types";
import Spinner from "../Spinner";
import { CSVLink } from "react-csv";
import { primaryColor, shippingMapping } from "../../utils/shared";
import OrderDetailsModal from "./OrderDetailsModal";

const Orders = () => {
  const token = useSelector((state: any) => state.user.token);
  const isAdmin = useSelector((state: any) => state.user.isAdmin);

  const getOrdersFunction = isAdmin ? getDashboardOrders : getMyOrders;
  const getOrderItemsFunction = isAdmin
    ? getOrderItemsDashboard
    : getOrderItems;

  const [orders, setOrders] = useState<OrderType[]>();
  const [shownOrders, setShownOrders] = useState<OrderType[]>();
  const [searchInput, setSearchInput] = useState<string>("");
  const [loadingOrders, setLoadingOrders] = useState<boolean>();
  const [error, setError] = useState<boolean>();
  const [tab, setTab] = useState<OrderStatusEnum>(OrderStatusEnum.CHECKING);

  const [currentOrder, setCurrentOrder] = useState<OrderType>();
  const [isOrderModalShown, setIsOrderModalShown] = useState<boolean>(false);

  const [downloadOrdersData, setDownloadOrdersData] = useState<
    { items: CartItemType[]; id: string }[]
  >([]);

  const fetchOrders = async () => {
    try {
      setLoadingOrders(true);
      setError(false);
      const o = (await getOrdersFunction(token, tab)).data.sort(
        (a: OrderType, b: OrderType) =>
          new Date(a.create_date).getTime() - new Date(b.create_date).getTime(),
      );
      setOrders(o);
      setLoadingOrders(false);
    } catch (err) {
      console.log(err);
      setLoadingOrders(false);
      setError(true);
    }
  };

  useEffect(() => {
    setShownOrders(
      searchInput === ""
        ? orders
        : orders?.filter(
            (order) =>
              order.phone_number.search(searchInput) !== -1 ||
              order.secondary_phone_number.search(searchInput) !== -1,
          ),
    );
  }, [searchInput, orders]);

  useEffect(() => {
    fetchOrders();
  }, [tab]);

  const fullScreen = () => {
    if (document.fullscreenElement !== null) {
      document.exitFullscreen();
    }
  };

  const handleOnOpenModal = (order: OrderType) => {
    setCurrentOrder(order);
    setIsOrderModalShown(true);
  };

  const handleOnChangeOrderStatus = async (
    id: string,
    status: OrderStatusEnum,
  ) => {
    try {
      setLoadingOrders(true);
      await changeOrderStatus(token, id, status);
      fetchOrders();
    } catch (err) {
    } finally {
      setLoadingOrders(false);
    }
  };

  const handleOnCancelOrder = async (id: string) => {
    try {
      setLoadingOrders(true);
      await cancelOrder(token, id);
      fetchOrders();
    } catch (err) {
    } finally {
      setLoadingOrders(false);
    }
  };
  const fetchOrdersDownload = async () => {
    try {
      setLoadingOrders(true);
      setError(false);
      const o = await getOrdersFunction(token, tab);
      setOrders(o.data);
      for (const order of o.data) {
        const items = (await getOrderItemsFunction(token, order.id)).data;
        setDownloadOrdersData((oDs) => [...oDs, { id: order.id, items }]);
      }
      setLoadingOrders(false);
    } catch (err) {
      setLoadingOrders(false);
      setError(true);
    }
  };

  const handleOnDownloadDataClick = async (event, done) => {
    await fetchOrdersDownload();
    done(true);
  };

  return (
    <Container className="align-items-center min-vh-100" onClick={fullScreen}>
      {currentOrder && (
        <OrderDetailsModal
          handleOnChangeOrderStatus={handleOnChangeOrderStatus}
          order={currentOrder}
          setShow={setIsOrderModalShown}
          show={isOrderModalShown}
          tab={tab}
        />
      )}

      <h2 className="p-5">{isAdmin ? "Orders" : "My Orders"}</h2>
      <Row
        style={{ backgroundColor: "#f5f5f5", gap: 10 }}
        className="p-3 rounded"
      >
        {Object.values(OrderStatusEnum).map((status) => (
          <Col
            key={status}
            role="button"
            className="rounded"
            onClick={() => setTab(status)}
            style={{
              backgroundColor: tab === status ? "#ffffff" : undefined,
              textAlign: "center",
            }}
          >
            <h3>{status}</h3>
          </Col>
        ))}
      </Row>
      {error ? (
        <Alert variant="warning">
          Server error while getting your orders try again later
        </Alert>
      ) : loadingOrders ? (
        <Spinner fullWidth={false} />
      ) : (
        <Row className="mt-5">
          {isAdmin && orders && orders.length !== 0 && (
            <Row>
              <Col>
                <Form className="d-flex mb-3 w-100 align-items-center">
                  <FormControl
                    type="text"
                    placeholder="Search By Phone"
                    className="mr-sm-2 me-2 border-secondary"
                    onChange={(e) => setSearchInput(e.target.value)}
                    value={searchInput}
                  />
                </Form>
              </Col>
              <Col>
                <CSVLink
                  data={orders.map((order) => ({
                    orderId: order.id,
                    totalPrice: order.total_price,
                    priceWithoutShipping:
                      order.total_price - shippingMapping[order.governorate],
                    shippingFess: shippingMapping[order.governorate],
                    address: order.address,
                    governorate: order.governorate,
                    city: order.city,
                    apartment: order.apartment,
                    firstName: order.first_name,
                    lastName: order.last_name,
                    phoneNumber: order.phone_number,
                    secondaryPhoneNumber: order.secondary_phone_number,
                    orderItemsNames:
                      downloadOrdersData
                        .find((o) => o.id === order.id)
                        ?.items.map((i) => i.name) ?? [],
                    orderItemsQuantities:
                      downloadOrdersData
                        .find((o) => o.id === order.id)
                        ?.items.map((i) => i.quantity) ?? [],
                    orderItemsColors:
                      downloadOrdersData
                        .find((o) => o.id === order.id)
                        ?.items.map((i) => i.color) ?? [],
                  }))}
                  asyncOnClick={true}
                  onClick={handleOnDownloadDataClick}
                  filename={`orders-${tab}.csv`}
                >
                  <Button
                    variant="danger"
                    style={{ backgroundColor: primaryColor }}
                    className="rounded-pill"
                  >
                    Download Data
                  </Button>
                </CSVLink>
              </Col>
            </Row>
          )}

          {shownOrders && shownOrders.length !== 0 ? (
            <>
              <h3>Number Of Orders : {shownOrders.length}</h3>
              {shownOrders?.map((order) => {
                const date = new Date(order.create_date);
                return (
                  <Col
                    md={5}
                    xs={11}
                    onClick={() => handleOnOpenModal(order)}
                    className="p-3 border m-3"
                    key={order.id}
                  >
                    <Row className="text-center">
                      <Col md={6}>
                        <h5>order id: #{order.id}</h5>
                      </Col>
                      {isAdmin && (
                        <Col md={6}>
                          <h5>
                            order : #{(parseInt(order.id) - 3216541) / 5616}
                          </h5>
                        </Col>
                      )}
                      <Col md={6}>
                        <h5>
                          created at : {date.getDate()}-{date.getMonth() + 1}-
                          {date.getFullYear()}
                        </h5>
                      </Col>
                      <Col md={6}>
                        <h5>total price: {order.total_price}</h5>
                      </Col>
                      <Col md={6}>
                        <h5>first name: {order.first_name}</h5>
                      </Col>
                      <Col md={12}>
                        <h5>Phone Number: {order.phone_number}</h5>
                      </Col>
                    </Row>
                    <br />
                  </Col>
                );
              })}
            </>
          ) : (
            <h2 style={{ textAlign: "center" }}>No orders in this tab</h2>
          )}
        </Row>
      )}
    </Container>
  );
};

export default Orders;
