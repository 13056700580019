import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

const initialState = {
  token: undefined,
  isAdmin: false,
};

export const userSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      state.isAdmin = state.token
        ? jwtDecode<{ type: string }>(state.token).type === "admin"
        : false;
    },
    removeToken: (state, action) => {
      state.token = undefined;
      state.isAdmin = false;
    },
  },
});

export const { setToken } = userSlice.actions;
export default userSlice.reducer;
