import sendApiRequest from "./send-api-request";

export const likeItem = async (
  params: string | string[] | object,
  token: string,
) => {
  return await sendApiRequest(
    "likes/",
    "post",
    params,
    {},
    { "x-auth-token": token },
  );
};

export const getMyLikes = async (token: string) => {
  return await sendApiRequest(
    "likes/",
    "get",
    {},
    {},
    { "x-auth-token": token },
  );
};
