import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { CartItemType, OrderStatusEnum, OrderType } from "../../types";
import CartItem from "../Cart/CartItem";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getOrderItems, getOrderItemsDashboard } from "../../api/order";

interface OrderDetailsModalProps {
  show: boolean;
  order: OrderType;
  tab: OrderStatusEnum;
  setShow: (b: boolean) => void;
  handleOnChangeOrderStatus: (
    id: string,
    orderStatus: OrderStatusEnum,
  ) => Promise<void>;
}

export default function OrderDetailsModal({
  show,
  order,
  tab,
  setShow,
  handleOnChangeOrderStatus,
}: OrderDetailsModalProps) {
  const isAdmin = useSelector((state: any) => state.user.isAdmin);

  const token = useSelector((state: any) => state.user.token);

  const getOrderItemsFunction = isAdmin
    ? getOrderItemsDashboard
    : getOrderItems;

  const [orderItems, setOrderItems] = useState<CartItemType[]>();

  useEffect(() => {
    const fetchOrderItems = async () => {
      setOrderItems(undefined);
      const items = (await getOrderItemsFunction(token, order.id)).data;
      setOrderItems(items);
    };
    fetchOrderItems();
  }, [order.id]);

  const navigate = useNavigate();

  const date = new Date(order.create_date);

  const handleOnConfirmOrder = async () => {
    await handleOnChangeOrderStatus(order.id, OrderStatusEnum.PREPARING);
  };
  const handleOnRejectOrder = async () => {
    await handleOnChangeOrderStatus(order.id, OrderStatusEnum.REJECTED);
  };
  const handleOnShippingOrder = async () => {
    await handleOnChangeOrderStatus(order.id, OrderStatusEnum.SHIPPED);
  };
  const handleOnDeliveredOrder = async () => {
    await handleOnChangeOrderStatus(order.id, OrderStatusEnum.DELIVERED);
  };
  const handleOnRetrieveOrder = async () => {
    await handleOnChangeOrderStatus(order.id, OrderStatusEnum.CHECKING);
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton={true} className="border-0" bsPrefix="">
        Order Details
      </Modal.Header>
      <Modal.Body>
        <div className="p-3 border">
          <Row>
            <Col>Order Id: #{order.id}</Col>
            <Col>
              created at : {date.getDate()}-{date.getMonth() + 1}-
              {date.getFullYear()}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>Total Price: E£ {order.total_price}</Col>
            {order.coupon_code !== "" && order.discount !== 0 ? (
              <>
                <Col>code used: {order.coupon_code}</Col>
                <Col>discount: {order.discount}%</Col>
              </>
            ) : undefined}
          </Row>
          <hr />

          <Row>
            <Col>Address: {order.address}</Col>
            <Col>Governorate: {order.governorate}</Col>
          </Row>
          <Row>
            <Col>City: {order.city}</Col>
            <Col>Apartment: {order.apartment}</Col>
          </Row>
          <Row>
            <Col>First Name: {order.first_name}</Col>
            <Col>Last Name: {order.last_name}</Col>
          </Row>
          <Row>
            <Col>Phone number: {order.phone_number}</Col>
          </Row>
          <Row>
            <Col>Secondary Phone number: {order.secondary_phone_number}</Col>
          </Row>
          <Row>
            <Col>Instagram Handle: {order.instagram_handle}</Col>
          </Row>
          <hr />
          {orderItems ? (
            orderItems.map((item) => (
              <>
                <CartItem
                  key={item.id}
                  item={item}
                  handleOnItemClick={() =>
                    navigate(`/items/details/${item.item_id}`)
                  }
                />
                <hr />
              </>
            ))
          ) : (
            <Spinner />
          )}
          <Row className="my-3">
            {isAdmin ? (
              <>
                {tab === OrderStatusEnum.CHECKING ? (
                  <>
                    <Col>
                      <Button
                        onClick={handleOnConfirmOrder}
                        variant="success"
                        className="rounded-pill"
                      >
                        Confirm
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={handleOnRejectOrder}
                        variant="danger"
                        className="rounded-pill"
                      >
                        Reject
                      </Button>
                    </Col>
                  </>
                ) : tab === OrderStatusEnum.PREPARING ? (
                  <Col>
                    <Button
                      className="rounded-pill"
                      onClick={handleOnShippingOrder}
                    >
                      Ship
                    </Button>
                  </Col>
                ) : tab === OrderStatusEnum.SHIPPED ? (
                  <Col>
                    <Button
                      className="rounded-pill"
                      onClick={handleOnDeliveredOrder}
                      variant="info"
                    >
                      Deliver
                    </Button>
                  </Col>
                ) : tab === OrderStatusEnum.REJECTED ? (
                  <Col>
                    <Button
                      className="rounded-pill"
                      onClick={handleOnRetrieveOrder}
                      variant="info"
                    >
                      Checking
                    </Button>
                  </Col>
                ) : (
                  <></>
                )}
              </>
            ) : undefined}
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}
