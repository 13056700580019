export const egyptGovernorates = [
  "Cairo (Al-Qahirah)",
  "Alexandria (Al-Iskandariyah)",
  "Giza (Al-Jizah)",
  "Port Said (Bur Sa'id)",
  "Suez (As-Suways)",
  "Luxor (Al-Uqsur)",
  "Red Sea (Al-Bahr al-Ahmar)",
  "Dakahlia (Ad-Daqahliyah)",
  "Beheira (Al-Buhayrah)",
  "Fayoum (Al-Fayyum)",
  "Gharbia (Al-Gharbiyah)",
  "Ismailia (Al-Ismailiyah)",
  "Menofia (Al-Minufiyah)",
  "Minya (Al-Minya)",
  "Qalyubia (Al-Qalyubiyah)",
  "New Valley (Al-Wadi al-Jadid)",
  "Sharqia (Ash-Sharqiyah)",
  "Sohag (Suhaj)",
  "Aswan (Aswan)",
  "Assiut (Asyut)",
  "Beni Suef (Bani Suwayf)",
  "Damietta (Dumyat)",
  "Kafr El Sheikh (Kafr ash-Shaykh)",
  "Matruh (Matruh)",
  "Qena (Qina)",
  "North Sinai (Shamal Sina)",
  "South Sinai (Janub Sina)",
];
