import { Card, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { colorEnum, ItemType, mapColors } from "../../types";
import LikeIcon from "../Common/LikeIcon";
import { useSelector } from "react-redux";
import ColorCircle from "../Common/ColorCircle";
import { useState } from "react";

interface ItemCardProps {
  item: ItemType;
  backgroundColor?: string;
  onLikeClick?: () => void;
  onClick?: () => void;
  onClickColor?: (color: colorEnum) => void;
}

const ItemCard = ({
  item,
  onLikeClick,
  backgroundColor,
  onClick,
  onClickColor,
}: ItemCardProps) => {
  const token = useSelector((state: any) => state.user.token);
  const { innerHeight: height } = window;

  const [selectedColor, setSelectedColor] = useState<colorEnum>();

  const navigate = useNavigate();
  const handleOnItemClick = (color: colorEnum) => {
    if (onClickColor !== undefined) {
      setSelectedColor(color);
      onClickColor(color);
      return;
    }
    if (onClick !== undefined) {
      onClick();
      return;
    }
    navigate(`/items/details/${item.id}`, { state: { color } });
    navigate(0);
  };
  return (
    <Card
      onClick={() => handleOnItemClick(item.colors[0])}
      className="border-0"
      style={{ backgroundColor }}
    >
      {onLikeClick !== undefined && token ? (
        <LikeIcon liked={item.liked} itemId={item.id} onPress={onLikeClick} />
      ) : undefined}

      {item.availability === false && (
        <div className="ribbon">Out Of Stock</div>
      )}

      {item.discount && parseFloat(item.discount) !== 0 && (
        <div className="ribbon">{item.discount} OFF</div>
      )}

      <Card.Img
        loading="lazy"
        variant="top"
        src={item.images[0][0]}
        style={{
          aspectRatio: 1,
          height: height / 4,
          objectFit: "cover",
          objectPosition: "50% 0%",
        }}
        className="rounded-5 item mb-2"
      />
      <div className="d-flex align-items-center flex-column">
        <p className="text-muted" style={{ fontSize: 18 }}>
          {item.name}
        </p>
        <div>
          {item.discount && parseFloat(item.discount) !== 0 && (
            <p
              className="text-decoration-line-through text-muted pe-3"
              style={{ display: "inline-block" }}
            >
              LE {item.price}
            </p>
          )}
          <p className="text-muted" style={{ display: "inline-block" }}>
            LE{" "}
            {(
              item.price *
              (1 - (parseFloat(item.discount) ?? 0) / 100)
            ).toFixed(2)}{" "}
            {!item.availability ? "Out of stock" : undefined}
          </p>
        </div>
        <Stack
          direction="horizontal"
          className="align-items-center justify-content-center"
          gap={2}
        >
          {item.colors.map((color) => (
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleOnItemClick(color);
              }}
              key={color}
            >
              <ColorCircle
                color={mapColors[color]}
                withStroke={selectedColor === color}
                width={40}
              />
            </div>
          ))}
        </Stack>
      </div>
    </Card>
  );
};

export default ItemCard;
