export const base_url = "richnesseg.com";
export const API_BASE_URL =
  "https://richness-backend-lv6rjsf27-ahmedsherif304s-projects.vercel.app/";

export const imagePathMaker = (s: string) => `/assets/${s}.webp`;
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const homeImagesKey = "home_images";
export const sectionOneImagesKey = "section_one_images";
export const sectionTwoImagesKey = "section_two_images";

export const primaryColor = "#C92300";

export const shippingMapping: Record<string, number> = {
  "Cairo (Al-Qahirah)": 85,
  "Alexandria (Al-Iskandariyah)": 85,
  "Giza (Al-Jizah)": 85,
  "Port Said (Bur Sa'id)": 90,
  "Suez (As-Suways)": 90,
  "Dakahlia (Ad-Daqahliyah)": 90,
  "Beheira (Al-Buhayrah)": 90,
  "Fayoum (Al-Fayyum)": 90,
  "Gharbia (Al-Gharbiyah)": 90,
  "Ismailia (Al-Ismailiyah)": 90,
  "Menofia (Al-Minufiyah)": 90,
  "Qalyubia (Al-Qalyubiyah)": 90,
  "Sharqia (Ash-Sharqiyah)": 90,
  "Beni Suef (Bani Suwayf)": 90,
  "Damietta (Dumyat)": 90,
  "Kafr El Sheikh (Kafr ash-Shaykh)": 90,
  "Luxor (Al-Uqsur)": 100,
  "Sohag (Suhaj)": 100,
  "Aswan (Aswan)": 100,
  "Minya (Al-Minya)": 100,
  "Assiut (Asyut)": 100,
  "Qena (Qina)": 100,
  "New Valley (Al-Wadi al-Jadid)": 125,
  "Matruh (Matruh)": 125,
  "North Sinai (Shamal Sina)": 125,
  "South Sinai (Janub Sina)": 125,
  "Red Sea (Al-Bahr al-Ahmar)": 125,
};
