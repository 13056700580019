import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Carousel,
  FormControl,
  InputGroup,
  Alert,
  Stack,
} from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getItems } from "../../api/items";
import Spinner from "../Spinner";
import {
  colorEnum,
  ItemRatingsType,
  ItemType,
  mapColorName,
  mapColors,
  mapSizes,
  sizeEnum,
} from "../../types";
import { addCartItem } from "../../api/cart";
import { useSelector } from "react-redux";
import LikeIcon from "../Common/LikeIcon";
import ShareIcon from "../Common/ShareIcon";
import ItemCard from "./ItemCard";
import ColorCircle from "../Common/ColorCircle";
import { getRatings, rateItem } from "../../api/rating";
import ReactStars from "react-rating-stars-component";
import { AxiosError } from "axios";
import { primaryColor } from "../../utils/shared";

const ItemDetailsSection = () => {
  const { innerWidth: width, innerHeight: height } = window;

  const { id } = useParams();
  const token = useSelector((state: any) => state.user.token);
  const { state } = useLocation();
  const defaultColor = state?.color as colorEnum;

  const navigate = useNavigate();

  const [item, setItem] = useState<ItemType>();
  const [itemRatings, setItemRatings] = useState<ItemRatingsType>();
  const [selectedSize, setSelectedSize] = useState<sizeEnum>(sizeEnum.MEDIUM);
  const [selectedColor, setSelectedColor] = useState<colorEnum>(defaultColor);
  const [images, setImages] = useState<string[]>([]);
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0);
  const [quantity, setQuantity] = useState(1);
  const [rating, setRating] = useState<number>(1);
  const [ratingMessage, setRatingMessage] = useState<string>("");
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);

  const [reviewFeedback, setReviewFeedback] = useState<string>("");
  const [fullScreenImage, setFullScreenImage] = useState<boolean>(false);

  const fetchRatings = async () => {
    const ratings = await (await getRatings(id)).data;
    setItemRatings(ratings);
  };

  const fetchData = async () => {
    const i = (await getItems({ id }, token)).data[0];
    setSelectedColor(defaultColor);
    if (i && i.colors) {
      const index = i.colors.findIndex((c) => c === defaultColor);
      setImages(i.images[index]);
      setActiveImageIndex(0);
    }
    setItem(i);
    await fetchRatings();
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (quantity > 99) setQuantity(99);
  }, [quantity]);

  useEffect(() => {
    if (item && item.colors) {
      const index = item.colors.findIndex((c) => c === selectedColor);
      setImages(item.images[index]);
      setActiveImageIndex(0);
    }
  }, [selectedColor]);

  const onLikePress = () => {
    fetchData();
  };

  const handleOnAddToCart = async () => {
    if (!token) {
      navigate("/login");
      return;
    }
    if (item.availability) {
      try {
        setError(undefined);
        setSuccess(false);
        await addCartItem(
          token,
          item.id,
          quantity,
          selectedColor,
          selectedSize,
        );
        setSuccess(true);
      } catch (err) {
        setError(err);
      }
    }
  };

  const handleOnBuyNow = async () => {
    await handleOnAddToCart();
    navigate("/cart");
  };

  const handleSizeClick = (size) => {
    setSelectedSize(size);
  };

  const handleColorClick = (color) => {
    setSelectedColor(color);
  };

  const handleQuantityChange = (event) => {
    const value = event.target.value;
    setQuantity(value ? parseInt(value) : 0);
  };

  const handleQuantityDeltaChange = (delta) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + delta));
  };

  const handleOnSubmitRating = async () => {
    try {
      setReviewFeedback("");
      await rateItem(token, id, rating, ratingMessage);
      setReviewFeedback("Review added successfully");
      fetchRatings();
    } catch (e) {
      if (e instanceof AxiosError) {
        setReviewFeedback(
          typeof e.response?.data === "string"
            ? e.response?.data
            : e.response?.data.errors.message,
        );
      }
    }
  };

  const fullScreen = () => {
    if (document.fullscreenElement !== null) {
      document.exitFullscreen();
      setFullScreenImage(false);
    }
  };
  return (
    <Container className="mt-3" onClick={fullScreen}>
      {item ? (
        <>
          {images && (
            <>
              <Carousel
                activeIndex={activeImageIndex}
                onSelect={(i) => setActiveImageIndex(i)}
              >
                {images.map((image, index) => (
                  <Carousel.Item key={image}>
                    <div
                      className="d-flex justify-content-center"
                      onClick={() => {
                        setFullScreenImage(true);
                        document
                          .getElementById(`item/image:/${item.id}/${index}`)
                          ?.requestFullscreen();
                      }}
                    >
                      <img
                        loading="lazy"
                        width="32"
                        height="32"
                        src="https://img.icons8.com/ios/50/zoom-in--v1.png"
                        alt="zoom-in--v1"
                        style={{
                          position: "absolute",
                          top: "2vh",
                          left: width * 0.15,
                          backgroundColor: "white",
                        }}
                        className="p-2 rounded-5"
                      />
                      <img
                        loading="lazy"
                        id={`item/image:/${item.id}/${index}`}
                        src={image}
                        style={
                          fullScreenImage
                            ? {
                                width,
                                height,
                                aspectRatio: 1,
                              }
                            : {
                                width: width * 0.7,
                                height: height * 0.55,
                                aspectRatio: 1,
                                objectFit: "cover",
                                objectPosition: "50% 0%",
                              }
                        }
                        alt={item.name}
                        className="rounded-5"
                      />
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
              {images.map((image, index) => (
                <img
                  loading="lazy"
                  key={`small/image/${index}`}
                  onClick={() => setActiveImageIndex(index)}
                  src={image}
                  height={height / 10}
                  className={
                    index === activeImageIndex
                      ? "border-3 border-bottom border-black mt-3 pe-3"
                      : "mt-3 pe-3"
                  }
                />
              ))}
            </>
          )}
          <Card className="border-0">
            <Card.Body>
              <LikeIcon
                liked={item.liked}
                itemId={item.id}
                onPress={onLikePress}
              />
              <ShareIcon itemId={item.id} />
              <h4>
                {item.name}{" "}
                {mapColorName[item.colors.find((c) => c === selectedColor)]}
              </h4>
              {itemRatings?.sum && (
                <div className="d-flex gap-2 flex-row align-items-center">
                  <ReactStars
                    count={5}
                    size={24}
                    activeColor={primaryColor}
                    edit={false}
                    value={itemRatings.sum / itemRatings.count}
                  />
                  {itemRatings.count} reviews
                </div>
              )}
              {item.discount && parseFloat(item.discount) !== 0 && (
                <p
                  className="text-decoration-line-through text-muted pe-5"
                  style={{ display: "inline-block" }}
                >
                  LE {item.price} EGP
                </p>
              )}
              <h6 style={{ display: "inline-block" }}>
                LE{" "}
                {(
                  item.price *
                  (1 - (parseFloat(item.discount) ?? 0) / 100)
                ).toFixed(2)}{" "}
                EGP
              </h6>
              <p style={{ fontSize: 12 }}>Shipping calculated at checkout</p>
              <h6>Color</h6>
              <Stack direction="horizontal" gap={3} className="mb-3">
                {item.colors.map((color) => (
                  <div onClick={() => handleColorClick(color)} key={color}>
                    <ColorCircle
                      color={mapColors[color]}
                      withStroke={color === selectedColor}
                      width={48}
                    />
                  </div>
                ))}
              </Stack>
              <h5>Description</h5>
              <p
                dangerouslySetInnerHTML={{
                  __html: item.description.replaceAll("\n", "<br />"),
                }}
              ></p>
              <h5>Size</h5>
              <Stack direction="horizontal" className="mb-3" gap={4}>
                {item.sizes
                  .sort()
                  .reverse()
                  .map((size) => (
                    <div
                      key={size}
                      style={{
                        backgroundColor:
                          size === selectedSize ? primaryColor : undefined,
                        color: size === selectedSize ? "white" : "black",
                      }}
                      className="border border-1 border-black py-2 px-4 rounded-pill"
                      onClick={() => handleSizeClick(size)}
                    >
                      {mapSizes[size]}
                    </div>
                  ))}
              </Stack>

              <Stack>
                <h5>Quantity</h5>
                <InputGroup className="border border-1 border-black w-50">
                  <div
                    className="p-2 text-center"
                    style={{
                      fontSize: 24,
                      color: quantity === 1 ? "gray" : "black",
                    }}
                    onClick={() => handleQuantityDeltaChange(-1)}
                  >
                    -
                  </div>
                  <FormControl
                    value={quantity}
                    onChange={handleQuantityChange}
                    className="border-0"
                    style={{
                      textAlign: "center",
                      WebkitAppearance: "textfield",
                    }}
                    min="1"
                    max="99"
                  />
                  <div
                    className="p-2 text-center"
                    style={{
                      fontSize: 24,
                      color: quantity === 99 ? "gray" : "black",
                    }}
                    onClick={() => handleQuantityDeltaChange(1)}
                  >
                    +
                  </div>
                </InputGroup>
              </Stack>
              <Button
                variant={"danger"}
                style={{ backgroundColor: primaryColor }}
                size="lg"
                className="my-5 w-100 rounded-pill"
                disabled={!item.availability}
                onClick={handleOnAddToCart}
              >
                Add to Cart
              </Button>
              <Button
                variant={"danger"}
                style={{ backgroundColor: primaryColor }}
                size="lg"
                className="mb-5 w-100 rounded-pill"
                disabled={!item.availability}
                onClick={handleOnBuyNow}
              >
                Buy it now
              </Button>

              {error ? (
                <Alert variant="danger">
                  error in adding the item to the cart
                </Alert>
              ) : undefined}
              {success ? (
                <Alert variant="success">
                  Item added successfully to the cart
                </Alert>
              ) : undefined}
            </Card.Body>
          </Card>
          {item.relatedItems !== undefined && item.relatedItems.length !== 0 ? (
            <Row className="mt-5">
              <hr />
              <h2 className="text-center my-5">Related Items</h2>
              {item.relatedItems.map((relatedItem) => (
                <Col xs={6} sm={6} md={3}>
                  <ItemCard key={relatedItem.id} item={relatedItem} />
                </Col>
              ))}
            </Row>
          ) : undefined}

          <div
            style={{ backgroundColor: primaryColor, color: "white" }}
            className="mb-3 w-100 text-center rounded py-2"
          >
            Write a review
          </div>
          <ReactStars
            count={5}
            size={24}
            activeColor={primaryColor}
            value={rating}
            onChange={setRating}
          />
          <FormControl
            as="textarea"
            rows={3}
            placeholder="Message"
            value={ratingMessage}
            onChange={(e) => setRatingMessage(e.target.value)}
          />
          <Button
            style={{ backgroundColor: primaryColor, width: "100%" }}
            className="my-3 rounded-pill"
            variant="danger"
            onClick={handleOnSubmitRating}
          >
            Submit
          </Button>
          {reviewFeedback !== "" && (
            <Alert variant="danger">{reviewFeedback}</Alert>
          )}
          <hr />
          <h2 className="text-center">Reviews</h2>
          {itemRatings?.messages?.map((message) => (
            <div>
              <ReactStars
                count={5}
                size={24}
                activeColor={primaryColor}
                value={message.rating}
                edit={false}
              />
              <h4>
                <img
                  loading="lazy"
                  width="24"
                  height="24"
                  src="https://img.icons8.com/ios/50/user--v1.png"
                  alt="user--v1"
                  className="me-3"
                />
                {message.username}
              </h4>
              <div>{message.message}</div>
              <hr />
            </div>
          ))}
        </>
      ) : (
        <Spinner />
      )}
    </Container>
  );
};

export default ItemDetailsSection;
