import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Row,
  Stack,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { addCoupon, getAllCoupons, removeCoupon } from "../../api/coupons";
import { CouponType } from "../../types";
import Spinner from "../Spinner";

export default function DashboardCoupon() {
  const token = useSelector((state: any) => state.user.token);

  const [coupons, setCoupons] = useState<CouponType[]>();

  const [couponExpiryDate, setCouponExpiryDate] = useState<string>();
  const [couponCode, setCouponCode] = useState<string>();
  const [couponPercentage, setCouponPercentage] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const fetchCoupons = async () => {
    try {
      setLoading(true);
      setError(false);
      const c = await getAllCoupons(token);
      setCoupons(c.data);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCoupons();
  }, []);

  const handleOnAddCoupon = async () => {
    try {
      setLoading(true);
      setError(false);
      await addCoupon(token, couponExpiryDate, couponPercentage, couponCode);
      await fetchCoupons();
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleOnDeleteCoupon = async (id: string) => {
    try {
      setLoading(true);
      setError(false);
      await removeCoupon(token, id);
      await fetchCoupons();
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="mt-5">
      <h2 className="pt-5">Coupons</h2>
      {loading ? (
        <Spinner />
      ) : error ? (
        <Alert variant="warning"> Server error please try again later</Alert>
      ) : (
        <>
          <Row className="m-5">
            {coupons &&
              coupons.map((coupon) => {
                const date = new Date(coupon.expiry_date);
                return (
                  <Row className="mb-3">
                    <Col>code: {coupon.code}</Col>
                    <Col>Percentage: {coupon.percentage}%</Col>
                    <Col>
                      expiry date: {date.getDate()}/{date.getMonth() + 1}/
                      {date.getFullYear()}
                    </Col>
                    <Col>
                      <Button
                        variant="danger"
                        onClick={() => handleOnDeleteCoupon(coupon.id)}
                      >
                        x
                      </Button>
                    </Col>
                    <hr />
                  </Row>
                );
              })}
          </Row>

          <hr />

          <h3>Add a new Coupon</h3>
          <Form onSubmit={handleOnAddCoupon}>
            <Row gap={5}>
              <Col>
                <FormControl
                  placeholder="code"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                />
              </Col>
              <Col>
                <FormControl
                  type="number"
                  min={1}
                  max={99}
                  placeholder="percentage"
                  value={couponPercentage}
                  onChange={(e) =>
                    setCouponPercentage(parseFloat(e.target.value))
                  }
                  required
                />
              </Col>
              <Col>
                <FormControl
                  type="date"
                  placeholder="expiry date"
                  value={couponExpiryDate}
                  onChange={(e) => setCouponExpiryDate(e.target.value)}
                  required
                />
              </Col>
              <Col>
                <Button variant="success" type="submit" className="mb-3">
                  Add Coupon
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </Container>
  );
}
