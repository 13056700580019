import { useEffect, useState } from "react";
import { getPackages } from "../../api/packages";
import { PackageType } from "../../types";
import { Alert, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function PackagesShowCase() {
  const { innerHeight: height } = window;
  const navigate = useNavigate();

  const [packages, setPackages] = useState<PackageType[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const fetchPackages = async () => {
    try {
      setLoading(true);
      setError(false);
      const c = await getPackages();
      setPackages(c.data);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  const handleOnPackClick = (packId: string) => {
    navigate(`/packages/details/${packId}`);
  };

  return (
    <Container className="p-3 min-vh-100">
      {loading ? (
        <Spinner />
      ) : error ? (
        <Alert variant="warning"> Server error please try again later</Alert>
      ) : (
        <>
          <h2>Special Offers</h2>
          <Row>
            {packages &&
              packages.map((pack) => (
                <Col md={3} xs={6} sm={6}>
                  <Card
                    onClick={() => handleOnPackClick(pack.id)}
                    className="border-0"
                  >
                    <Card.Img
                      loading="lazy"
                      variant="top"
                      src={
                        "https://img.freepik.com/free-vector/special-offer-creative-sale-banner-design_1017-16284.jpg?1"
                      }
                      style={{
                        aspectRatio: 1,
                        height: height / 4,
                        objectFit: "cover",
                        objectPosition: "50% 0%",
                      }}
                      className="rounded-5 item mb-2"
                    />
                    <div className="d-flex align-items-center flex-column">
                      <p className="text-muted" style={{ fontSize: 18 }}>
                        {pack.name}
                      </p>
                      <div>
                        <p
                          className="text-muted"
                          style={{ display: "inline-block" }}
                        >
                          LE {pack.total_price}
                        </p>
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
          </Row>
        </>
      )}
    </Container>
  );
}
