export enum sizeEnum {
  MEDIUM = "medium",
  LARGE = "large",
  X_LARGE = "Xlarge",
}

export enum colorEnum {
  WHITE = "white",
  BLACK = "black",
  OLIVE = "olive",
  BABY_PINK = "baby_pink",
  NAVY_BLUE = "navy_blue",
  GREY = "grey",
}

export enum OrderStatusEnum {
  CHECKING = "checking",
  PREPARING = "preparing",
  SHIPPED = "shipped",
  DELIVERED = "delivered",
  CANCELED = "canceled",
  REJECTED = "rejected",
}

export type ItemType = {
  images: string[][];
  name: string;
  price: number;
  description: string;
  category_id: string;
  id: string;
  availability: boolean;
  sizes: sizeEnum[];
  colors: colorEnum[];
  liked: boolean;
  relatedItems?: ItemType[];
  discount?: string;
};

export interface CartItemType extends Omit<ItemType, "images"> {
  quantity: number;
  color: string;
  size: string;
  item_id: string;
  images: string[];
}

export interface CartPricesType {
  finalPrice: number;
  totalPriceWithoutDiscount: number;
  shippingFees: number;
  discount: number;
  totalPriceAfterDiscount: number;
  firstOrderDiscount: number;
}

export interface OrderType {
  id: string;
  first_name: string;
  last_name: string;
  total_price: number;
  coupon_code: string;
  discount: number;
  create_date: string;
  address: string;
  governorate: string;
  city: string;
  apartment: string;
  image: string;
  phone_number: string;
  secondary_phone_number: string;
  instagram_handle: string;
}

export interface FiveStarRatingType {
  message: string;
  username: string;
  item_id: string;
}
export interface ItemRatingsType {
  messages: { rating: number; message: string; username: string }[];
  sum: number;
  count: number;
}

export const mapSizes: Record<sizeEnum, string> = {
  [sizeEnum.MEDIUM]: "S / M",
  [sizeEnum.LARGE]: "L",
  [sizeEnum.X_LARGE]: "XL",
};

export const mapColors: Record<colorEnum, string> = {
  [colorEnum.WHITE]: "#fff",
  [colorEnum.BLACK]: "#000",
  [colorEnum.BABY_PINK]: "#F4C2C2",
  [colorEnum.OLIVE]: "#636b2f",
  [colorEnum.NAVY_BLUE]: "#000080",
  [colorEnum.GREY]: "#403e43",
};

export const mapColorName: Record<colorEnum, string> = {
  [colorEnum.WHITE]: "white",
  [colorEnum.BLACK]: "black",
  [colorEnum.BABY_PINK]: "pink",
  [colorEnum.OLIVE]: "olive",
  [colorEnum.NAVY_BLUE]: "navyBlue",
  [colorEnum.GREY]: "grey",
};

export interface CategoryType {
  id: string;
  name: string;
  image: string;
}

export interface CouponType {
  id: string;
  code: string;
  expiry_date: string;
  percentage: number;
}

export interface PackageType {
  id: string;
  name: string;
  item_ids: string[];
  total_price: number;
}
