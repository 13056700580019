import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Row,
  Spinner,
  Stack,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { addPackage, getPackages, removePackage } from "../../api/packages";
import { ItemType, PackageType } from "../../types";
import { getItems } from "../../api/items";
import { MultiSelect } from "react-multi-select-component";

export default function DashboardPackage() {
  const token = useSelector((state: any) => state.user.token);

  const [packages, setPackages] = useState<PackageType[]>();
  const [newPackageName, setNewPackageName] = useState<string>();
  const [items, setItems] = useState<ItemType[]>();
  const [itemIds, setItemIds] = useState<{ value: string; label: string }[]>(
    [],
  );
  const [totalPrice, setTotalPrice] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const fetchPackages = async () => {
    try {
      setLoading(true);
      setError(false);
      const i = await getItems();
      const c = await getPackages();
      setPackages(c.data);
      setItems(i.data);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  const handleOnAddPackage = async () => {
    try {
      setLoading(true);
      setError(false);
      if (itemIds.length === 0) {
        throw new Error("Please fill the ids");
      }
      await addPackage(
        token,
        newPackageName,
        itemIds.map((itemId) => itemId.value),
        totalPrice,
      );
      await fetchPackages();
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleOnDeletePackage = async (packId: string) => {
    try {
      setLoading(true);
      setError(false);
      await removePackage(token, packId);
      await fetchPackages();
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="mt-5">
      <h2 className="pt-5">Packages</h2>
      {loading ? (
        <Spinner />
      ) : error ? (
        <Alert variant="warning">Server error please try again later</Alert>
      ) : (
        <>
          <Row className="m-5">
            {packages &&
              packages.map((pack) => (
                <Row key={pack.id}>
                  <Col>
                    <h5>{pack.name}</h5>
                    <h5>{pack.total_price}</h5>
                    <h5>
                      {pack.item_ids.map((id) => (
                        <span key={id}>
                          {items[items.findIndex((i) => i.id === id)].name} ,{" "}
                        </span>
                      ))}
                    </h5>
                  </Col>
                  <Col>
                    <Button
                      variant="danger"
                      onClick={() => handleOnDeletePackage(pack.id)}
                    >
                      x
                    </Button>
                  </Col>
                  <hr />
                </Row>
              ))}
          </Row>
          <hr />
          <Stack direction="vertical" gap={100}>
            <h3>Add a new package</h3>
            <Form onSubmit={handleOnAddPackage}>
              <FormControl
                className="mb-3"
                type="text"
                placeholder="package name"
                value={newPackageName}
                onChange={(e) => setNewPackageName(e.target.value)}
                required
              />
              <MultiSelect
                className="mb-3"
                options={
                  items !== undefined
                    ? items.map((item) => {
                        return { label: item.name, value: item.id };
                      })
                    : []
                }
                labelledBy="Select"
                value={itemIds}
                onChange={(i) => setItemIds(i)}
              />
              <FormControl
                className="mb-3"
                type="number"
                placeholder="total price"
                value={totalPrice}
                onChange={(e) => setTotalPrice(parseFloat(e.target.value))}
                required
              />
              <Button variant="success" type="submit" className="mb-3">
                Add Package
              </Button>
            </Form>
          </Stack>
        </>
      )}
    </Container>
  );
}
