// src/App.js
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import AuthorizationModal from "./components/Authentication/AuthorizationModal";
import HomePage from "./components/Homepage";
import Footer from "./components/Footer";
import NavbarComponent from "./components/Navbar";
import ErrorPage from "./components/ErrorPage";
import ItemDetailsSection from "./components/Item/ItemDetailsSection";
import CategoryDetails from "./components/Category/CategoryDetails";
import Cart from "./components/Cart/Cart";
import MyLikes from "./components/likes/MyLikes";
import AdminRoute from "./components/AdminRoute";
import Orders from "./components/orders/Orders";
import DashboardCategory from "./components/Category/DashboardCategory";
import DashboardCoupon from "./components/Coupon/DashboardCoupon";
import { useSelector } from "react-redux";
import DashboardItem from "./components/Item/DashboardItem";
import Policy from "./components/Policy";
import AboutUs from "./components/AboutUs";
import Shop from "./components/Shop";
import ContactUs from "./components/ContactUs";
import DashboardItemEdit from "./components/Item/DashboardItemEdit";
import DashboardItemShowCase from "./components/Item/DashboardItemeShowCase";
import DashboardEditCategory from "./components/Category/DashboardEditCategory";
import DashboardPackage from "./components/Package/DashboardPackage";
import PackagesShowCase from "./components/Package/PackagesShowCase";
import PackageDetails from "./components/Package/PackageDetails";

const App = () => {
  const isAdmin = useSelector((state: any) => state.user.isAdmin);

  const [showHomeModal, setShowHomeModal] = useState<boolean>(true);

  return (
    <>
      <NavbarComponent />
      <Routes>
        <Route path="/category/:id" element={<CategoryDetails />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/packages" element={<PackagesShowCase />} />
        <Route path="/packages/details/:id" element={<PackageDetails />} />
        <Route path="/items/details/:id" element={<ItemDetailsSection />} />
        <Route element={<PrivateRoute />}>
          <Route path="/cart" element={<Cart />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/myOrders" element={<Orders />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/myLikes" element={<MyLikes />} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route path="/dashboard/ordersTrack" element={<Orders />} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route
            path="/dashboard/manageItems"
            element={<DashboardItemEdit />}
          />
        </Route>
        <Route element={<AdminRoute />}>
          <Route path="/dashboard/manageItem" element={<DashboardItemEdit />} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route
            path="/dashboard/addCategory"
            element={<DashboardCategory />}
          />
        </Route>
        <Route element={<AdminRoute />}>
          <Route
            path="/dashboard/editCategory"
            element={<DashboardEditCategory />}
          />
        </Route>
        <Route element={<AdminRoute />}>
          <Route
            path="/dashboard/homeImages"
            element={<DashboardItemShowCase />}
          />
        </Route>
        <Route element={<AdminRoute />}>
          <Route path="/dashboard/addCoupon" element={<DashboardCoupon />} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route path="/dashboard/addItem" element={<DashboardItem />} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route path="/dashboard/packages" element={<DashboardPackage />} />
        </Route>
        <Route path="/login" element={<AuthorizationModal />} />
        <Route
          path="/"
          element={
            <HomePage
              setShowHomeModal={setShowHomeModal}
              showHomeModal={showHomeModal}
            />
          }
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>

      {!isAdmin && <Footer />}
    </>
  );
};

export default App;
