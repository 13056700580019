import sendApiRequest from "./send-api-request";

export const getCategories = async (params?: string | string[] | object) => {
  return await sendApiRequest("categories/", "get", params, {});
};

export const addCategory = async (
  token: string,
  name: string,
  image: string,
) => {
  return await sendApiRequest(
    "categories/",
    "post",
    {},
    { name, image },
    { "x-auth-token": token },
  );
};

export const editCategory = async (
  token: string,
  id: string,
  name: string,
  image: string,
) => {
  return await sendApiRequest(
    `categories/${id}`,
    "put",
    {},
    { name, image },
    { "x-auth-token": token },
  );
};
