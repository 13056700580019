import { OrderStatusEnum } from "../types";
import sendApiRequest from "./send-api-request";

export const makeOrder = async (body: object, token: string) => {
  return await sendApiRequest("orders/", "post", {}, body, {
    "x-auth-token": token,
  });
};

export const getMyOrders = async (token: string, status: OrderStatusEnum) => {
  return await sendApiRequest(
    "orders/",
    "get",
    { status },
    {},
    {
      "x-auth-token": token,
    },
  );
};

export const getOrderItems = async (token: string, orderId: string) => {
  return await sendApiRequest(
    `orders/${orderId}`,
    "get",
    {},
    {},
    {
      "x-auth-token": token,
    },
  );
};

export const getDashboardOrders = async (
  token: string,
  status: OrderStatusEnum,
) => {
  return await sendApiRequest(
    "orders/dashboard",
    "get",
    { status },
    {},
    {
      "x-auth-token": token,
    },
  );
};

export const getOrderItemsDashboard = async (
  token: string,
  orderId: string,
) => {
  return await sendApiRequest(
    `orders/dashboard/${orderId}`,
    "get",
    {},
    {},
    {
      "x-auth-token": token,
    },
  );
};

export const changeOrderStatus = async (
  token: string,
  orderId: string,
  status: OrderStatusEnum,
) => {
  return await sendApiRequest(
    `orders/status/${orderId}`,
    "post",
    {},
    {
      status,
    },
    {
      "x-auth-token": token,
    },
  );
};

export const cancelOrder = async (token: string, orderId: string) => {
  return await sendApiRequest(
    `orders/cancel/${orderId}`,
    "post",
    {},
    {},
    {
      "x-auth-token": token,
    },
  );
};
