import { capitalizeFirstLetter, primaryColor } from "../../utils/shared";

interface CategoryCardProps {
  name: string;
  image: string;
}
export default function CategoryCard({ name, image }: CategoryCardProps) {
  const { innerHeight: height } = window;

  return (
    <div>
      <img
        loading="lazy"
        src={image}
        style={{
          width: "100%",
          aspectRatio: 1,
          height: height / 2,
          objectFit: "cover",
          objectPosition: "50% 0%",
        }}
        className="rounded-5"
      />
      <h4
        style={{
          position: "relative",
          bottom: 30,
          zIndex: 1,
          backgroundColor: primaryColor,
          color: "white",
        }}
        className="text-center p-3 rounded-bottom-5"
      >
        {capitalizeFirstLetter(name)}
      </h4>
    </div>
  );
}
