import sendApiRequest from "./send-api-request";

export const getCouponByCode = async (token: string, code: string) => {
  return await sendApiRequest(
    "coupons/",
    "get",
    { code },
    {},
    { "x-auth-token": token },
  );
};

export const getAllCoupons = async (token: string) => {
  return await sendApiRequest(
    "coupons/all",
    "get",
    {},
    {},
    { "x-auth-token": token },
  );
};

export const addCoupon = async (
  token: string,
  expiryDate: string,
  percentage: number,
  code: string,
) => {
  return await sendApiRequest(
    "coupons",
    "post",
    {},
    { percentage, expiryDate, code },
    { "x-auth-token": token },
  );
};

export const removeCoupon = async (token: string, id: string) => {
  return await sendApiRequest(
    `coupons/${id}`,
    "delete",
    {},
    {},
    { "x-auth-token": token },
  );
};
