import { useEffect, useState } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { ItemType } from "../../types";
import { getItems } from "../../api/items";
import ItemCard from "./ItemCard";

export default function BestSellers() {
  const [latestReleasedItems, setLatestReleasedItems] = useState<ItemType[]>();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const items = (await getItems({ promoted: true, page_size: 10 })).data;
        setLatestReleasedItems(items.sort((a, b) => a.price - b.price));
      } catch (err) {}
    };
    fetchData();
  }, []);

  return (
    <Container>
      {" "}
      {latestReleasedItems && latestReleasedItems.length !== 0 ? (
        <>
          <h1 className="my-4 text-center">Best Sellers</h1>
          <Row>
            {latestReleasedItems.map((item) => (
              <Col md={3} xs={6} sm={6} key={item.id}>
                <ItemCard key={item.id} item={item} />
              </Col>
            ))}
          </Row>
        </>
      ) : undefined}
    </Container>
  );
}
