import sendApiRequest from "./send-api-request";

export const getRatings = async (itemId: string) => {
  return await sendApiRequest(`rating/${itemId}`, "get", {}, {}, {});
};

export const rateItem = async (
  token: string,
  itemId: string,
  rating: number,
  message: string,
) => {
  return await sendApiRequest(
    `rating/${itemId}`,
    "post",
    {},
    { rating, message },
    { "x-auth-token": token },
  );
};

export const getFiveStarRatings = async () => {
  return await sendApiRequest(`rating/fiveStars`, "get");
};
