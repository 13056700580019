import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import { getCategories } from "../../api/categories";
import Spinner from "../Spinner";
import {
  CategoryType,
  colorEnum,
  mapColors,
  mapSizes,
  sizeEnum,
} from "../../types";
import { addItem, editItem } from "../../api/items";
import { useSelector } from "react-redux";
import ColorCircle from "../Common/ColorCircle";
import { imagePathMaker } from "../../utils/shared";
import { useLocation } from "react-router-dom";
import ReactSwitch from "react-switch";

export default function DashboardItem() {
  const token = useSelector((state: any) => state.user.token);
  const { state } = useLocation();
  const {
    category_id: defaultCategoryId,
    colors: defaultColors,
    description: defaultDescription,
    name: defaultName,
    price: defaultPrice,
    sizes: defaultSizes,
    images: defaultImages,
    discount: defaultDiscount,
    promoted: defaultPromoted,
    availability,
    id: defaultItemId,
  } = state?.item || {};

  const [categories, setCategories] = useState<CategoryType[]>();

  const [itemName, setItemName] = useState<string>(defaultName ?? "");
  const [price, setPrice] = useState<number>(defaultPrice);
  const [categoryId, setCategoryId] = useState<string>(defaultCategoryId);
  const [description, setDescription] = useState<string>(defaultDescription);
  const [discount, setDiscount] = useState<string>(defaultDiscount);
  const [promoted, setPromoted] = useState<boolean>(defaultPromoted);
  const [outOfStock, setOutOfStock] = useState<boolean>(!availability);
  const [colors, setColors] = useState<
    { color: colorEnum; images: string[] }[]
  >(
    defaultColors && defaultImages
      ? defaultColors.map((color, index) => ({
          color,
          images: defaultImages[index],
        }))
      : [],
  );
  const [sizes, setSizes] = useState<sizeEnum[]>(defaultSizes ?? []);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);

  const fetchCategories = async () => {
    try {
      setLoading(true);
      setError("");
      const c = await getCategories();
      setCategories(c.data);
    } catch (e) {
      setError(
        typeof e.response?.data === "string"
          ? e.response?.data
          : e.response?.data.errors.message,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleOnSubmit = async () => {
    try {
      setLoading(true);
      setSuccess(false);
      setError("");
      if (!colors.every((c) => c.images.length !== 0)) {
        throw { response: { data: "please add images to each color" } };
      }
      if (sizes.length === 0)
        throw { response: { data: "please choose at least one size" } };
      if (colors.length === 0)
        throw { response: { data: "please choose at least one color" } };
      if (defaultCategoryId !== undefined) {
        await editItem(
          token,
          {
            name: itemName,
            price,
            description,
            promoted,
            colors,
            sizes,
            discount,
            availability: !outOfStock,
          },
          defaultItemId,
        );
      } else {
        await addItem(
          token,
          { name: itemName, price, description, promoted, colors, sizes },
          categoryId,
        );
      }
      setSuccess(true);
    } catch (e) {
      setError(
        typeof e.response?.data === "string"
          ? e.response?.data
          : e.response?.data?.errors?.message,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleOnChangeImage = (s: any, color: colorEnum) => {
    const newImages = [];
    const i = s.split(/[^0-9]+/);
    for (const image of i) {
      if (parseInt(image)) newImages.push(imagePathMaker(image));
    }
    setColors((cs) =>
      cs.map((c) => {
        if (c.color === color) return { color, images: newImages };
        else return c;
      }),
    );
  };

  const handleChooseColor = (color: colorEnum) => {
    if (colors.some((c) => c.color === color)) {
      setColors((cs) => cs.filter((c) => c.color !== color));
    } else setColors((colors) => [...colors, { color, images: [] }]);
  };

  const handleChooseSize = (size: sizeEnum) => {
    if (sizes.includes(size)) {
      setSizes((cs) => cs.filter((c) => c !== size));
    } else setSizes((sizes) => [...sizes, size]);
  };

  return (
    <Container className="mt-5">
      <h1 className="pt-5">Add Item</h1>
      {loading ? (
        <Spinner />
      ) : error !== "" ?? !categories ? (
        <Alert variant="warning">
          {" "}
          {error !== "" ? error : "Server error please try again later"}
        </Alert>
      ) : (
        <Form onSubmit={handleOnSubmit}>
          {success && <Alert variant="success">Item added successfully</Alert>}

          <Row>
            <Col>
              <FormControl
                required
                type="text"
                placeholder="Name"
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
              />
            </Col>
            <Col>
              <FormControl
                required
                type="number"
                placeholder="Price"
                value={price}
                onChange={(e) => setPrice(parseFloat(e.target.value))}
              />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <Form.Select
                onChange={(e) => setCategoryId(e.target.value)}
                value={categoryId}
                required
              >
                <option>choose category</option>
                {categories?.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col>
              <FormControl
                required
                type="text"
                as="textarea"
                rows={3}
                placeholder="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mt-5 gap-5">
            <h3>Choose Colors</h3>
            {Object.values(colorEnum).map((color, index) => (
              <Col key={index}>
                <div onClick={() => handleChooseColor(color)}>
                  <ColorCircle
                    color={mapColors[color]}
                    width={50}
                    withStroke={colors.some((c) => c.color === color)}
                  />
                </div>
                {colors.some((c) => c.color === color) ? (
                  <>
                    <h5>Images</h5>
                    <Col className="mt-3">
                      <input
                        type="string"
                        defaultValue={colors
                          .find((c) => c.color === color)
                          .images.map((image) => image.match(/\d+/))
                          .join(",")}
                        onChange={(e) =>
                          handleOnChangeImage(e.target.value, color)
                        }
                      />
                    </Col>
                    <Col>
                      {colors.find((c) => c.color === color).images &&
                        colors
                          .find((c) => c.color === color)
                          .images?.map((image, index) => (
                            <img
                              loading="lazy"
                              src={image}
                              key={index}
                              height={100}
                              className="me-2 rounded border border-3"
                            />
                          ))}
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </Col>
            ))}
          </Row>
          <Row className="mt-5">
            <h3>Choose Sizes</h3>
            <ButtonGroup>
              {Object.values(sizeEnum).map((size, index) => (
                <Col key={index} onClick={() => handleChooseSize(size)}>
                  <Button
                    key={size}
                    variant={
                      sizes.includes(size)
                        ? "outline-primary"
                        : "outline-secondary"
                    }
                    className="me-5"
                  >
                    {mapSizes[size]}
                  </Button>
                </Col>
              ))}
            </ButtonGroup>
          </Row>
          {defaultItemId !== undefined ? (
            <Row className="mt-5">
              <h3>Discount %</h3>
              <FormControl
                type="text"
                placeholder="Discount"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
              />
            </Row>
          ) : undefined}
          <Row className="mt-5">
            <Col>
              <h2>Promoted</h2>
              <ReactSwitch
                checked={promoted}
                onChange={(e) => setPromoted(e)}
                aria-label="promoted"
              />
            </Col>
            <Col>
              <h2>Out Of Stock </h2>
              <ReactSwitch
                checked={outOfStock}
                onChange={(e) => setOutOfStock(e)}
                aria-label="outOfStock"
              />
            </Col>
          </Row>

          <Button variant="success" type="submit" className="my-5">
            {defaultCategoryId !== undefined ? "Edit Item" : "Add Item"}
          </Button>
        </Form>
      )}
    </Container>
  );
}
