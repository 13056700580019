import sendApiRequest from "./send-api-request";

export const getItems = async (
  params?: string | string[] | object,
  token?: string,
) => {
  const items = await sendApiRequest(
    "items/",
    "get",
    params,
    {},
    { "x-auth-token": token },
  );
  return {
    data: await items.data.map((item) => {
      let sizes = item.sizes;
      sizes = sizes?.replace("{", "").replace("}", "").split(",");
      return { ...item, sizes };
    }),
  };
};

export const getLatestReleasedItems = async () => {
  return await sendApiRequest("items/latestReleases", "get");
};

export const getBestSellers = async () => {
  return await sendApiRequest("items/bestSellers", "get");
};

export const addItem = async (
  token: string,
  body: Object,
  categoryId: string,
) => {
  await sendApiRequest(`items/${categoryId}`, "post", {}, body, {
    "x-auth-token": token,
  });
};

export const editItem = async (token: string, body: Object, itemId: string) => {
  await sendApiRequest(`items/${itemId}`, "put", {}, body, {
    "x-auth-token": token,
  });
};
