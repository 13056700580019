import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

const PriceFilter = ({
  maxPrice,
  onChange,
}: {
  maxPrice: number;
  onChange: (min: number, max: number) => void;
}) => {
  const [priceRange, setPriceRange] = useState({ min: 0, max: maxPrice });

  useEffect(() => {
    if (priceRange.min > priceRange.max)
      setPriceRange({ min: priceRange.min, max: priceRange.min });
    onChange(priceRange.min, priceRange.max);
  }, [priceRange]);

  const handleMinChange = (e) => {
    setPriceRange((prevRange) => ({
      ...prevRange,
      min: Number(e.target.value),
    }));
  };

  const handleMaxChange = (e) => {
    setPriceRange((prevRange) => ({
      ...prevRange,
      max: Number(e.target.value),
    }));
  };

  const handleSliderChange = (e) => {
    setPriceRange((prevRange) => ({
      ...prevRange,
      [e.target.name]: Number(e.target.value),
    }));
  };

  return (
    <Form.Group>
      <Form.Label>Price</Form.Label>
      <Row>
        <Col>
          <Form.Control
            type="number"
            value={priceRange.min}
            onChange={handleMinChange}
            name="min"
          />
        </Col>
        <Col>
          <Form.Control
            type="number"
            value={priceRange.max}
            onChange={handleMaxChange}
            name="max"
          />
        </Col>
      </Row>
      <div className="custom-slider">
        <Form.Range
          className="my-2"
          min={0}
          max={maxPrice}
          value={priceRange.min}
          name="min"
          onChange={handleSliderChange}
        />
      </div>
      <div className="custom-slider">
        <Form.Range
          min={0}
          max={maxPrice}
          value={priceRange.max}
          name="max"
          onChange={handleSliderChange}
        />
      </div>
    </Form.Group>
  );
};

export default PriceFilter;
