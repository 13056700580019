import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMyLikes } from "../../api/like";
import { ItemType } from "../../types";
import Spinner from "../Spinner";
import ItemCard from "../Item/ItemCard";

export default function MyLikes() {
  const token = useSelector((state: any) => state.user.token);

  const [myLikes, setMyLikes] = useState<ItemType[]>();
  const [loadingItems, setLoadingItems] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  const fetchMyLikes = async () => {
    try {
      setLoadingItems(true);
      setError(false);
      const items = (await getMyLikes(token)).data;
      setMyLikes(items);
    } catch (err) {
      setError(true);
    } finally {
      setLoadingItems(false);
    }
  };

  useEffect(() => {
    fetchMyLikes();
  }, []);

  return (
    <Container className="min-vh-100">
      <h2 className="py-5">My Likes</h2>
      {error ? (
        <h3>Error in getting your likes please try again later</h3>
      ) : loadingItems ? (
        <Spinner fullWidth={false} />
      ) : (
        <Row>
          {myLikes && myLikes.length !== 0
            ? myLikes.map((item) => (
                <Col md={3} xs={6} sm={6}>
                  <ItemCard item={item} />{" "}
                </Col>
              ))
            : undefined}
        </Row>
      )}
    </Container>
  );
}
