import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  colorEnum,
  ItemType,
  mapColorName,
  mapSizes,
  PackageType,
  sizeEnum,
} from "../../types";
import { addPackageToCart, getPackages } from "../../api/packages";
import { getItems } from "../../api/items";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Stack,
} from "react-bootstrap";
import ItemCard from "../Item/ItemCard";
import { primaryColor } from "../../utils/shared";
import { useSelector } from "react-redux";

export default function PackageDetails() {
  const token = useSelector((state: any) => state.user.token);

  const navigate = useNavigate();
  const { id } = useParams();

  const [pack, setPackage] = useState<PackageType>();
  const [items, setItems] = useState<ItemType[]>();
  const [selectedSizes, setSelectedSizes] = useState<sizeEnum[]>([]);
  const [selectedColors, setSelectedColors] = useState<colorEnum[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState(false);

  const fetchPackages = async () => {
    try {
      setLoading(true);
      setError(false);
      const c = await getPackages();
      const p = c.data.find((pack) => pack.id === id);
      setPackage(p);
      const i = [];
      for (const itemId of p.item_ids) {
        i.push((await getItems({ id: itemId })).data[0]);
      }
      setItems(i);
      setSelectedColors(i.map((item) => item.colors[0]));
      setSelectedSizes(i.map((item) => item.sizes[0]));
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  const handleOnAddToCart = async () => {
    if (!token) {
      navigate("/login");
      return;
    }
    try {
      setError(undefined);
      setSuccess(false);
      await addPackageToCart(token, pack.id, selectedColors, selectedSizes);
      setSuccess(true);
    } catch (err) {
      setError(err);
    }
  };

  const handleOnBuyNow = async () => {
    await handleOnAddToCart();
    navigate("/cart");
  };

  const handleSizeClick = async (index: number, size: sizeEnum) => {
    setSelectedSizes((sizes) => {
      sizes[index] = size;
      return [...sizes];
    });
  };
  const handleColorClick = async (index: number, color: colorEnum) => {
    setSelectedColors((colors) => {
      colors[index] = color;
      return [...colors];
    });
  };

  return (
    <Container className="p-3 min-vh-100">
      {loading ? (
        <Spinner />
      ) : error ? (
        <Alert variant="warning"> Server error please try again later</Alert>
      ) : (
        <>
          {pack && (
            <>
              <Row>
                {items &&
                  items.map((item, index) => (
                    <Col md={3} xs={6} sm={6} key={item.id}>
                      <ItemCard
                        item={item}
                        onClickColor={(color) => handleColorClick(index, color)}
                      />
                      <Stack direction="horizontal" className="mb-3" gap={4}>
                        {item.sizes
                          .sort()
                          .reverse()
                          .map((size) => (
                            <div
                              key={size}
                              style={{
                                backgroundColor:
                                  size === selectedSizes[index]
                                    ? primaryColor
                                    : undefined,
                                color:
                                  size === selectedSizes[index]
                                    ? "white"
                                    : "black",
                              }}
                              className="border border-1 border-black py-2 px-4 rounded-pill"
                              onClick={() => handleSizeClick(index, size)}
                            >
                              {mapSizes[size]}
                            </div>
                          ))}
                      </Stack>
                    </Col>
                  ))}
              </Row>
              <h2 className="align-text-center">
                Total Price : {pack.total_price}
              </h2>
              <Button
                variant={"danger"}
                style={{ backgroundColor: primaryColor }}
                size="lg"
                className="my-5 w-100 rounded-pill"
                onClick={handleOnAddToCart}
              >
                Add to Cart
              </Button>
              <Button
                variant={"danger"}
                style={{ backgroundColor: primaryColor }}
                size="lg"
                className="mb-5 w-100 rounded-pill"
                onClick={handleOnBuyNow}
              >
                Buy it now
              </Button>
              {error ? (
                <Alert variant="danger">
                  error in adding the item to the cart
                </Alert>
              ) : undefined}
              {success ? (
                <Alert variant="success">
                  Item added successfully to the cart
                </Alert>
              ) : undefined}
            </>
          )}
        </>
      )}
    </Container>
  );
}
