import {
  CloseButton,
  Col,
  Dropdown,
  Fade,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";

interface SearchBarProps {
  searchInput: string;
  suggestions?: any[];
  showDropDown: boolean;
  height: number;
  setShowSearchBar: (b: boolean) => void;
  handleOnSuggestionPress: (id: any) => void;
  setShowDropDown: (b: boolean) => void;
  setSearchInput: (a: string) => void;
}

export default function SearchBar({
  searchInput,
  suggestions,
  showDropDown,
  height,
  setShowSearchBar,
  handleOnSuggestionPress,
  setShowDropDown,
  setSearchInput,
}: SearchBarProps) {
  const handleBlur = () => {
    setTimeout(() => setShowDropDown(false), 100);
  };

  return (
    <div
      style={{
        height,
        position: "absolute",
        top: 0,
        backgroundColor: "white",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
      }}
      className="d-flex"
    >
      <Form className="d-flex me-3 w-50 align-items-center">
        <FormControl
          onBlur={handleBlur}
          onFocus={() => setShowDropDown(true)}
          type="text"
          placeholder="Search"
          className="mr-sm-2 me-2 border-secondary"
          onChange={(e) => setSearchInput(e.target.value)}
          value={searchInput}
        />
        <CloseButton onClick={() => setShowSearchBar(false)} />
      </Form>

      {suggestions && showDropDown && suggestions.length > 0 && (
        <Dropdown.Menu
          show
          className="mt-2"
          style={{
            top: height,
            alignItems: "center",
            alignSelf: "center",
            width: "95%",
          }}
        >
          {suggestions.map((suggestion, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => handleOnSuggestionPress(suggestion.id)}
            >
              <Row
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="mx-2"
              >
                <Col>
                  <img
                    loading="lazy"
                    src={suggestion.images[0][0]}
                    height={100}
                  />
                </Col>
                <Col>{suggestion.name}</Col>
                <Col>
                  <div>
                    E£{" "}
                    {(
                      suggestion.price *
                      (1 - (parseFloat(suggestion.discount) ?? 0) / 100)
                    ).toFixed(2)}
                  </div>
                </Col>
              </Row>
              <hr />
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
    </div>
  );
}
