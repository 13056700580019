import { useEffect, useState } from "react";
import { Alert, Button, Container } from "react-bootstrap";
import {
  homeImagesKey,
  imagePathMaker,
  sectionOneImagesKey,
  sectionTwoImagesKey,
} from "../../utils/shared";
import { addSetting, getSetting } from "../../api/settings";
import { useSelector } from "react-redux";

export default function DashboardItemShowCase() {
  const token = useSelector((state: any) => state.user.token);

  const [homeImages, setHomeImages] = useState<string>();
  const [sectionOneImages, setSectionOneImages] = useState<string>();
  const [sectionTwoImages, setSectionTwoImages] = useState<string>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const setting = (await getSetting(homeImagesKey)).data;
      const section1 = (await getSetting(sectionOneImagesKey)).data;
      const section2 = (await getSetting(sectionTwoImagesKey)).data;
      setHomeImages(setting.value ?? "");
      setSectionOneImages(section1.value ?? "");
      setSectionTwoImages(section2.value ?? "");
    };
    fetchData();
  }, []);

  const handleOnSubmit = async () => {
    setIsSuccess(false);
    try {
      await addSetting(token, homeImagesKey, homeImages);
      await addSetting(token, sectionOneImagesKey, sectionOneImages);
      await addSetting(token, sectionTwoImagesKey, sectionTwoImages);
      setIsSuccess(true);
    } catch (err) {}
  };
  return (
    <Container className="d-flex flex-column gap-3 mt-5">
      <h1>Home Images</h1>
      <input
        value={homeImages}
        type="string"
        onChange={(e) => setHomeImages(e.target.value)}
      />
      {homeImages &&
        homeImages
          ?.split(/[^0-9]+/)
          .map((image) => (
            <img loading="lazy" src={imagePathMaker(image)} width={100} />
          ))}

      <h1>Section 1 Images</h1>
      <input
        value={sectionOneImages}
        type="string"
        onChange={(e) => setSectionOneImages(e.target.value)}
      />
      {sectionOneImages &&
        sectionOneImages
          ?.split(/[^0-9]+/)
          .map((image) => (
            <img loading="lazy" src={imagePathMaker(image)} width={100} />
          ))}

      <h1>Section 2 Images</h1>
      <input
        value={sectionTwoImages}
        type="string"
        onChange={(e) => setSectionTwoImages(e.target.value)}
      />
      {sectionTwoImages &&
        sectionTwoImages
          ?.split(/[^0-9]+/)
          .map((image) => (
            <img loading="lazy" src={imagePathMaker(image)} width={100} />
          ))}

      <Button onClick={handleOnSubmit}>Submit</Button>
      <Alert variant="success" show={isSuccess}>
        Images added successfully
      </Alert>
    </Container>
  );
}
