import sendApiRequest from "./send-api-request";

export const addSetting = async (token: string, key: string, value: string) => {
  return await sendApiRequest(
    `settings/`,
    "post",
    {},
    { key, value },
    { "x-auth-token": token },
  );
};

export const getSetting = async (key: string) => {
  return await sendApiRequest(`settings/`, "get", { key }, {});
};
