import { useEffect, useState } from "react";
import { Container, Nav, Offcanvas, Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ItemType } from "../types";
import { getItems } from "../api/items";
import ItemCard from "./Item/ItemCard";
import logo from "../assets/RN.png";
import { setToken } from "../redux/user";
import { primaryColor } from "../utils/shared";
import { useNavigate } from "react-router-dom";

interface CanvasProps {
  showOffCanvas: boolean;
  setShowOffCanvas: (b: boolean) => void;
}

export default function Canvas({
  showOffCanvas,
  setShowOffCanvas,
}: CanvasProps) {
  const token = useSelector((state: any) => state.user.token);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(setToken(undefined));
  };

  const handleOnLoginPress = () => {
    navigate("/login");
    setShowOffCanvas(false);
  };

  return (
    <Offcanvas show={showOffCanvas} onHide={() => setShowOffCanvas(false)}>
      <Offcanvas.Header
        closeButton
        closeVariant="white"
        style={{ backgroundColor: primaryColor, color: "white" }}
      >
        <Offcanvas.Title>Richness</Offcanvas.Title>
      </Offcanvas.Header>
      <Nav.Link href="/" className="my-3 p-3 offCanvasItem">
        Home
      </Nav.Link>
      <Nav.Link href="/packages" className="mb-3 p-3 offCanvasItem">
        Special Offers{" "}
        <img
          width="48"
          height="48"
          src="https://img.icons8.com/fluency/48/hot-price.png"
          alt="hot-price"
        />
      </Nav.Link>
      <Nav.Link href="/aboutUs" className=" mb-3 p-3 offCanvasItem">
        <span>About Us</span>
      </Nav.Link>
      <Nav.Link href="/contactUs" className=" mb-3 p-3 offCanvasItem">
        <span>Contact Us</span>
      </Nav.Link>
      <Nav.Link href="/shop" className=" mb-3 p-3 offCanvasItem">
        <span>Shop</span>
      </Nav.Link>
      <Nav.Link href="/policy" className=" mb-3 p-3 offCanvasItem">
        <span>Policy</span>
      </Nav.Link>
      {token ? (
        <>
          <Nav.Link href="/myOrders" className=" mb-3 p-3 offCanvasItem">
            <span>My Orders</span>
          </Nav.Link>
          <Nav.Link href="/myLikes" className=" mb-3 p-3 offCanvasItem">
            <span>My Likes</span>
          </Nav.Link>
        </>
      ) : undefined}
      <Nav.Link
        onClick={() => (token ? handleLogout() : handleOnLoginPress())}
        className=" mb-3 p-3 offCanvasItem"
      >
        <span>{token ? "Logout" : "Login"}</span>
      </Nav.Link>
    </Offcanvas>
  );
}
