import { colorEnum, sizeEnum } from "../types";
import sendApiRequest from "./send-api-request";

export const getCartItems = async (token: string) => {
  return await sendApiRequest(
    "cart/",
    "get",
    {},
    {},
    { "x-auth-token": token },
  );
};

export const getCartPrices = async (
  token: string,
  couponCode: string,
  governorate: string,
) => {
  return await sendApiRequest(
    "cart/prices",
    "get",
    {
      couponCode,
      governorate,
    },
    {},
    { "x-auth-token": token },
  );
};

export const addCartItem = async (
  token: string,
  itemId: string,
  quantity: number,
  color: colorEnum,
  size: sizeEnum,
) => {
  return await sendApiRequest(
    "cart/",
    "post",
    {},
    {
      itemId,
      quantity,
      color,
      size,
    },
    { "x-auth-token": token },
  );
};

export const removeItemFromCart = async (token: string, cartItemId: string) => {
  return await sendApiRequest(
    "cart/",
    "delete",
    {},
    {
      cartItemId,
    },
    { "x-auth-token": token },
  );
};
